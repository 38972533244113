  <!--FOOTER CODE-->
  <footer class="footer-sec" style="background-image: url('assets/images/footer-bg.png')">
    <div class="container">
      <div class="row footer-bar">
        <div class="col-sm-4">
          <div class="footer-left-logo">
            <a href="{{domainName}}" class="footer-img"><img src="assets/images/logo.png"></a>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="footer-center-logo">
            <a href="javascript:void(0):" class="footer-img"><img src="assets/images/atol_logo.png"></a>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="footer-right-logo">
            <a href="javascript:void(0):" class="footer-img"><img src="assets/images/footer-right-logo.png"></a>
          </div> 
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-6">
          <h3 class="ftr-link-heading">About Seamaster Yacht Charter</h3>
          <p>Based in Kingston upon Thames, we are run by a personable team that have a huge amount of experience in providing wonderful sailing holidays to thousands of clients over the years. The team believes that sailing holidays should be easy, fun and accessible to all.</p>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6">
          <h3 class="ftr-link-heading">Useful  Links</h3>
          <ul class="ftr-link-list">
            <li><a href="{{domainName}}">Home</a></li>
            <li><a href="{{domainName}}">Destinations</a></li>
            <li><a href="{{domainName}}">Inspiration</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6">
          <h3 class="ftr-link-heading">Help &amp; Support</h3>
          <ul class="ftr-link-list">
            <li><a href="{{domainName}}">Privacy Policy</a></li>
            <li><a href="{{domainName}}">Terms & Conditions</a></li>
            <li><a href="{{domainName}}">Order Policy</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6">
          <h3 class="ftr-link-heading">Social Links</h3>
          <ul class="ftr-link-list">
            <div class="footer-social-icon">
              <li><a href="javascript:void(0);" class="social-icon"><i class="fab fa-linkedin-in"></i></a></li>
              <li><a href="javascript:void(0);" class="social-icon"><i class="fab fa-twitter"></i></a></li>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <!-- copyright-sec start here-->
    <div class="copyright-sec">
      <div class="copyright-content">
        <p>Copyright © 2020. All Rights Reserved.</p>
      </div>        
    </div>
    <!-- copyright-sec end here-->
  </footer>