<section class="listing-page_div" id="listing-page_div">
    <div class="container">
      <!-- form-start  -->
      <div class="listing_form">
        <div class="main-form">
                <ul>
                  <li>
                    <label>Country</label>
                    <select id="country" name="country" class="form-control selectpicker countrypicker" [(ngModel)]="yacht.destination" data-default="Croatia"
                    data-flag="true" (change)="loadDataSet2($event)">
                    <option *ngFor="let loc of location" id="{{loc.cuid}}" value="{{loc.name}}" attr.data-content='<span class="flag-icon flag-icon-{{loc.code}}"></span> {{loc.name}}'>{{loc.name}}</option>
                    </select>
                  </li>
                  <li>
                     
                      
                  <label>Regions</label>
                  <angular2-multiselect [data]="itemList" 
                      [(ngModel)]="selectedItems" 
                      [settings]="settings" 
                      (onSelect)="onItemSelect($event)"
                      (onDeSelect)="OnItemDeSelect($event)" 
                      (onSelectAll)="onSelectAll($event)" 
                      (onDeSelectAll)="onDeSelectAll($event)">
                  </angular2-multiselect>
                    <!-- <button type="button" class="region-s" id="region">Select Region<i class="fa fa-caret-down" aria-hidden="true"></i></button>
                  
                      <div *ngFor="let loc of location">
                        <div id="{{loc.id}}" class="region-select" style="z-index: 5; position: absolute; background-color: #fff; border: 1px solid #dadada; box-shadow: 1px;">
                        <div *ngFor="let isL of loc['isLands']">
                          <input type="checkbox" class="islandchkbox" id="{{isL.id}}" value="{{isL.label}}" [(ngModel)]="isL.isChecked" (change)="changeSelectionIsland($event)">
                          <label for="{{isL.id}}" class="island-label"> {{isL.label}}</label><br>
                          <div class="region-names">
                              <div class="region-name" *ngFor="let reg of isL['regions']">
                                <input class="{{isL.id}}" type="checkbox" id="{{reg.id}}" name="region[]" value="{{reg.label}}" [(ngModel)]="reg.isChecked" (change)="changeSelection($event)">
                                <label for="{{reg.id}}" class="region-label">{{reg.label}}</label>
                              </div>
                          </div>
                          </div>
                        </div>
                      </div> -->
                  </li>
                            <li>				 
                    <div class="input-field" >
                      <label for="cstm-datepicker">Duration</label>
                        <input type="text" id="cstm-datepicker" [(ngModel)]="minMaxDate" >
                        <i class="fa fa-calendar" aria-hidden="true" (click)="showCalender()"></i>
                      </div>					   
                  </li>
                            <li>
                    <label for="people">No. of people</label>
                    <select id="people" name="people" class="form-control selectpicker" [(ngModel)]="yacht.noOfPeople">
                                  <option selected="" value="2">2 People</option>
                                  <option value="3">3 People</option>
                                  <option value="4">4 People</option>
                                  <option value="5">5 People</option>
                                  <option value="6">6 People</option>
                                  <option value="7">7 People</option>
                                  <option value="8">8 People</option>
                                  <option value="9">9 People</option>
                                  <option value="10">10 People</option>
                                </select>
                  </li>
                                          <li>
                    <label for="skipper">Crew required</label>
                    <select id="skipper" name="skipper" class="form-control selectpicker" [(ngModel)]="yacht.skipper">
                                <option value="Boat only">Boat only</option>
                                <option value="Skipper Required">Skipper Required</option>
                                <option value="Host Required">Host Required</option>
                                <option value="Skipper and Host Required">Skipper and Host Required</option>
                                </select>
                  </li>
                          <li><input type="button" class="submitbtnyatch" id="submit" value="Search" (click)="getYachtsData()"> </li>
                </ul>
              </div>
            </div>
            <!-- form-end  -->
            <!-- filters-form start  -->
            
            <div class="listing_filter_form">
              <ul>
                <li class="dropdown">
                  <a href="javascript:void(0);" id="yacht-1" class="dropdown-toggle" data-toggle="dropdown" >
                    <img src="assets/images/boat-type.png" alt="boat-type" class="img-fluid">Boat Type</a>
                  <div class="dropdown-menu cstm-dropdown-menu" id="yacht-2">
                    <div class="region-name">
                          <input type="checkbox" id="all_boats" value="" name="all_boat"  class="all_boats_type" (change)="fetchAllBoats()">
                          <label for="all_boats">All Boats</label>
                    </div>
                    <div class="region-name" *ngFor="let boatType of boatTypes">
                      <input type="checkbox" id="{{boatType.id}}" value="{{boatType.label}}" name="boat_type[]" [(ngModel)]="boatType.isChecked" (change)="changeBoatTypeSelection($event)" class="boat_types">
                      <label for="{{boatType.id}}">{{boatType.label}}</label>
                    </div>
                  </div>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" ><img src="assets/images/boat-lenth.png" alt="boat-lenth" class="img-fluid">Boat Length</a>
                  <div class="dropdown-menu cstm-dropdown-menu" id="boat-length">
                      
                       <div class="range-slider">
                      <span id="boatlengthrangevalues" class="rangeValues"> {{inputBoatLength[0]}} -  {{inputBoatLength[1]}}</span><span>(m)</span>
                      <nouislider [min]="minboatrange" [max]="maxboatrange" [step]="0.1" [(ngModel)]="inputBoatLength" (change)="getBoatLength($event)"></nouislider>
                    </div>
                    <!-- <div class="range-slider">
                      <span id="boatlengthrangevalues" class="rangeValues">4 - 33</span><span>(m)</span>
                      <input value="4" name="minboatlen" [(ngModel)]="minboatlen" id="minboatlen" min="4" max="33" step="0.1"  type="range">
                      <input value="33" name="maxboatlen" [(ngModel)]="maxboatlen" min="4" max="33" step="0.1" type="range">
                    </div> -->
                  </div>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" ><img src="assets/images/boat-age.png" alt="toilet" class="img-fluid">Boat Age</a>
                    <div class="dropdown-menu cstm-dropdown-menu" id="boat-age">
                      <div class="range-slider">
                        <span class="rangeValues">{{inputBoatAge[0]}} -  {{inputBoatAge[1]}}</span>
                        <nouislider [min]="minboatagerange" [max]="maxboatagerange" [step]="1" [(ngModel)]="inputBoatAge" (change)="getBoatAge()" ></nouislider>
                      </div>
                      <!-- <div class="range-slider">
                        <span class="rangeValues">1942 - 2021</span>
                        <input value="1942" name="minboatage" [(ngModel)]="minboatage" min="1942" max="2021" step="1" type="range">
                        <input value="2021" name="maxboatage" [(ngModel)]="maxboatage" min="1942" max="2021" step="1" type="range">
                      </div> -->
                    </div>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" ><img src="assets/images/cabins.png" alt="cabins" class="img-fluid">Cabins</a>
                  <div class="dropdown-menu cstm-dropdown-menu" id="boat-cabin"> 
                      <div class="range-slider">
                          <span class="rangeValues">{{inputBoatCabin[0]}} -  {{inputBoatCabin[1]}}</span>
                          <nouislider [min]="minboatcabinrange" [max]="maxboatcabinrange" [step]="1" [(ngModel)]="inputBoatCabin" (change)="getBoatCabin()"></nouislider>
                        </div>
                    <!-- <div class="range-slider">
                      <span class="rangeValues">1 - 12</span>
                      <input value="1" name="mincabin" [(ngModel)]="mincabin" min="1" max="12" step="1" type="range">
                      <input value="12" name="maxcabin" [(ngModel)]="maxcabin" min="1" max="12" step="1" type="range">
                    </div> -->
                  </div>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" ><img src="assets/images/toilet.png" alt="toilet" class="img-fluid">Toilet</a>
                  <div class="dropdown-menu cstm-dropdown-menu" id="boat-toilet" >
                      <div class="range-slider">
                          <span class="rangeValues">{{inputBoatToilet[0]}} -  {{inputBoatToilet[1]}}</span>
                          <nouislider [min]="minboattoiletrange" [max]="maxboattoiletrange" [step]="1" [(ngModel)]="inputBoatToilet" (change)="getBoatToilet()"></nouislider>
                      </div>
                      <!--<div class="range-slider">
                          <span class="rangeValues">1 - 5</span>
                          <input value="1" name="mintoilet" [(ngModel)]="mintoilet" min="1" max="5" step="1" type="range">
                          <input value="5" name="maxtoilet" [(ngModel)]="maxtoilet" min="1" max="5" step="1" type="range">
                        </div> -->
                  </div>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" ><img src="assets/images/budget.png" alt="budget" class="img-fluid">Budget</a>
                  <div class="dropdown-menu cstm-dropdown-menu" id="boat-budget">
                    <div class="range-slider">
                      <span class="rangeValues">£{{numberWithCommas(inputBoatBudget[0])}} - £{{numberWithCommas(inputBoatBudget[1])}} </span>
                      <nouislider [min]="minboatpricerange" [max]="maxboatpricerange" [step]="1" [(ngModel)]="inputBoatBudget" (change)="getBoatBudget()"></nouislider>
                    </div>
                    <!-- <div class="range-slider">
                        <span class="rangeValues">100 - 2000</span><span>(£)</span>                 
                        <input value="100" name="minprice" [(ngModel)]="minprice" min="100" max="20000" step="1" type="range">
                        <input value="20000" name="maxprice" [(ngModel)]="maxprice" min="100" max="20000" step="1" type="range">
                    </div> -->
                  </div>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" ><img src="assets/images/more-filter.png" alt="more-filter" class="img-fluid">More Filters</a>
                  <div class="dropdown-menu cstm-dropdown-menu" id="more-filter">
                      <div class="region-name" *ngFor="let facilityType of facilityTypes">
                          <input type="checkbox" id="{{facilityType.id}}" value="{{facilityType.label}}" name="facility_type[]" [(ngModel)]="facilityType.isChecked" (change)="changeFacilityTypeSelection()" class="facility_types">
                          <label for="{{facilityType.id}}">{{facilityType.label}}</label>
                      </div>
                  </div>
                </li>
              </ul>
              <div class="cart once_fixed">
                <a target="_blank" routerLink="/favourites"  [queryParams]="{ids: strfavIds, destination: yacht.destination, dateValue: yacht.dateValue, dateEndValue: yacht.dateEndValue, noOfPeople: yacht.noOfPeople, skipper: yacht.skipper}" routerLinkActive="active" >
                <i class="fas fa-heart"></i><p>
                <span class="count" id="#edit-count-checked-checkboxes">({{favIds.length}})</span></p> 
                </a>
              </div>
            </div>
            <!-- filters-form end  -->
            <!-- favourites-sec start  -->
            <div class="top_content">
              <div class="top_content_left">
                <p id="result">Showing {{objectKeys(yachts).length}} Boats in {{showresults.destination}} for the {{showresults.daterange}}</p>
              </div>
              <div class="top_content_right">
                <a target="_blank" routerLink="/favourites"  [queryParams]="{ids: strfavIds, destination: yacht.destination, dateValue: yacht.dateValue, dateEndValue: yacht.dateEndValue, noOfPeople: yacht.noOfPeople, skipper: yacht.skipper}" routerLinkActive="active" >
                <div class="cart">
                  <i class="fas fa-heart"></i><p>Favourites
                  <span class="count" id="#edit-count-checked-checkboxes">({{favIds.length}})</span></p> 
                </div>
              </a>
                <!-- jquery -->
                <p>Sort By</p>
                  <div class="sorting-filter">
                    <select id="img_filter" class="form-control pricefilter selectpicker" (change)="sortByPrice()" [(ngModel)]="sortBy">
                      <option value="asc">Price: Low to High</option>
                      <option value="desc">Price: High to Low</option>
                    </select>
                  </div>
    
                  
              </div>
            </div>
            <!-- favourites-sec end  -->
    
            <!-- Yacht-card Start  -->
            <div class="loader_container">
              <div id="load" class="center"></div>
            </div>
            <div class="yacht_container">
            <div class="row" id="yachts" *ngIf="yachts.length">
              <div class="col-lg-3 col-md-4 col-sm-12 visibleyatchs" style="display: none;" *ngFor="let yatchItem of yachts">
                <div class="img-gallery_card">
                  <a href="javascript:void(0);">
                    <figure>
                      <a target="_blank" href="{{domainName}}/detail-page?id={{yatchItem['id']}}&location={{yacht.destination}}&noOfPeople={{yacht.noOfPeople}}&skipper={{yacht.skipper}} &dateValue={{yacht.dateValue}}&dateEndValue={{yacht.dateEndValue}}">
                      <img src="{{(yatchItem['ProfileURL'])? yatchItem['ProfileURL'] : 'assets/images/yatch-img.png'}}" class="img-fluid" class="yacht-card_img">
                      </a> 
                      <div class="img-content" *ngIf="yatchItem['Annotation']">
                        <p>{{yatchItem['Annotation']}}</p>
                      </div>
                    <div class="heart_icon">
                      <div class="favourites">
                          <span class="heart heart-unchecked" (click)="likeUnlike($event)" attr.data-id="{{yatchItem['id']}}"><i class="fa_heart_o" onclick="var state = this.className.indexOf('fa_heart_o') > -1; $(this).toggleClass('fa_heart_o', !state).toggleClass('fa_heart', state);"></i></span>  
                      </div>
                    </div>
                  </figure>
                  </a>
                  <figcaption>
                    <div class="figcaption_title">
                      <h4><a target="_blank" href="{{domainName}}/detail-page?id={{yatchItem['id']}}&location={{yacht.destination}}&noOfPeople={{yacht.noOfPeople}}&skipper={{yacht.skipper}} &dateValue={{yacht.dateValue}}&dateEndValue={{yacht.dateEndValue}}">{{yatchItem['Model']}}</a></h4>
                      <p><span title="{{yatchItem['Location_country']}}" class="flag-icon flag-icon-{{getCountryFlag(yatchItem['Location_country'])}}"></span>{{yatchItem['BaseName']}}, {{yatchItem['searchfieldfromwebsite']}}</p>
                      <p class="loc_region_name">{{yatchItem['Location_region_name']}}</p>
                      
                    </div>
                    <hr>
                    <div class="figcaption_content">
                      <ul><div><p> Type</p><span>{{yatchItem['type']}}</span> </div> </ul>
                      <ul><div><p> Year</p><span>{{yatchItem['Boat_Age']}}</span> </div> </ul>
                      <ul><div><p> Length</p><span>{{yatchItem['Boat_Length']}}m</span> </div> </ul>
                      <ul><div><p> Cabins</p><span>{{yatchItem['Cabins']}}</span> </div> </ul>
                      <ul><div><p> Toilets</p><span>{{yatchItem['Toilet']}} </span> </div> </ul>
                    </div>
                    <hr>
                    <div class="figcaption_features">
                      <h4>Key Features</h4>
                        <div class="figcaption_features__img">
                          <img src="assets/images/facilities/{{facility['name']}}.png" class="img-fluid" alt="{{facility['name']}}" title="{{facility['name']}}" *ngFor="let facility of yatchItem['Facilities']" >
                          <!-- <img src="assets/images/wind.png" class="img-fluid" alt="wind" title="{{facility.name}}"> -->
                        </div>
                    </div>
                    <hr>
                    <div class="figcaption_bottom" >
                        <div class="figcaption_bottom_content">
                          <!-- <ul>
                            <li><div>You are saving<span><del>£{{numberWithCommas(yatchItem['Price'])}}</del> </span> </div></li>
                            <li><div>£{{numberWithCommas(yatchItem['Savings'])}}<span>  £{{numberWithCommas(yatchItem['DiscountedPrice'])}} </span> </div></li>
                            <li><div>{{yatchItem['DiscountPercent']}} % Off<span>Yacht  only price</span> </div></li>
                          </ul> -->
                          <ul>
                            <li><div><del>£{{numberWithCommas(yatchItem['Price'])}}</del><span>  £{{numberWithCommas(yatchItem['DiscountedPrice'])}} </span> </div></li>
                            <li><div>{{yatchItem['DiscountPercent']}} % Off<span>{{getSkipperText(yacht.skipper)}}</span> </div></li>
                          </ul>
                        </div>
                        <!-- <div class="figcaption_bottom_content">
                            <ul>
                              <li>
                                <div>
                                  <span>Price and discount are empty</span>
                                </div>
                              </li>
                            </ul>
                          </div> -->
                      </div>
                  </figcaption>
                </div>
              </div>
            </div>
            <!-- <div class="load_more">
                <a href="javascript:void(0);" id="loadMore">Load More</a>
            </div> -->
            <div class="row" class="no_data_found"  *ngIf="!yachts.length">
                No data Found!
            </div>
            </div>
            
            <!-- Yacht-card Start  -->
          </div>
      </section>
