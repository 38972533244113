import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Yachts } from './yachts';
import { Enquiry } from './enquery';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApicallService {
  constructor(private httpClient: HttpClient) {}
  // tslint:disable-next-line:typedef
  getYachts(yacht: Yachts[]) {
    console.log(yacht);
    return this.httpClient.post<any>(`https://seamaster.secure.force.com/services/apexrest/getYachts/`, yacht).
    pipe(
          map((data: any) => {
            return data;
          }), catchError( error => {
            return throwError( 'Something went wrong!' );
          })
      );
    };

    // tslint:disable-next-line:typedef
    getEnquiry(dataEnquiry: any) {
      console.log('New Api Data ', dataEnquiry);      
      return this.httpClient.post<any>(`https://hicpartial-seamaster.cs109.force.com/services/apexrest/postInquiries/`, dataEnquiry).
      pipe(
            map((data2: any) => {
              return data2;
            }), catchError( error => {
              return throwError( 'Something went wrong!' );
            })
        );
      }

    // tslint:disable-next-line:typedef
    getEmailApi(dataEmailApi: any) {
      console.log('New Api Data ', dataEmailApi);     
      // const data =  {
      //   "First_Name__c" : "fasfsfs",
      //   "Last_Name__c" : "vsdvsdf",
      //   "Phone__c" : "54436464356",
      //   "Email__c" : "rayhan@yopmail.com",
      //   "action" : "home_page_form_mail_data"
      // } 
      const formData = new FormData();
      formData.append('First_Name__c', dataEmailApi.First_Name__c);
      formData.append('Last_Name__c', dataEmailApi.Last_Name__c);
      formData.append('Phone__c', dataEmailApi.Phone__c);
      formData.append('Email__c', dataEmailApi.Email__c);
      formData.append('action','home_page_form_mail_data');


      return this.httpClient.post<any>(`https://dev.seamaster.co.uk/wp-admin/admin-ajax.php`, formData).
      pipe(
            map((emailApi: any) => {
              return emailApi;
            }), catchError( error => {
              return throwError( 'Something went wrong!' );
            })
        );
      }
}
