import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Yachts } from '../../yachts';
import { ApicallService } from '../../apicall.service';
import { FormGroup, FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import { element } from 'protractor';
declare var $: any;

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.css']
})
export class FavouritesComponent implements OnInit {
  objectKeys = Object.keys;
  yachts = [];
  data = [];
  yacht: any = {};
  dataYachts = [];
  response: any = {};
  id: any = '';
  idsArray = Array();


  constructor(public http: HttpClient, private apiService: ApicallService, private router: Router, private route: ActivatedRoute){
    this.route.queryParams.subscribe(params => {
    this.yacht = {
        destination : (params.destination ? params.destination : 'Croatia'),
        // tslint:disable-next-line:no-unused-expression
        regions : '',
        dateValue : (params.dateValue ? params.dateValue : ''),
        dateEndValue : (params.dateEndValue ? params.dateEndValue : ''),
        noOfPeople : (params.noOfPeople ? params.noOfPeople : ''),
        skipper : (params.skipper ? params.skipper : ''),
    };

    this.idsArray = params.ids.split(',');
});
}



  ngOnInit(): void {
    $('.loader_container_fav').show();
    this.data  = JSON.parse(localStorage.getItem('dataYachts') || '{}');
    if (this.objectKeys(this.data ).length > 0) {
      this.response = this.data;
      this.getFilterData();
    }else{
    this.apiService.getYachts(this.yacht).subscribe((res) => {
      console.log(JSON.parse(res));
      this.response = JSON.parse(res);
      this.response = this.response.yachts;
      this.getFilterData();
    });
  }
}

  // tslint:disable-next-line:typedef
getFilterData(){
    // tslint:disable-next-line:no-shadowed-variable
    const result1 = this.response.filter((element: { id: string } ) => {
      // tslint:disable-next-line:no-unused-expression
      return ( this.idsArray.length > 0 ? this.idsArray.indexOf(element.id) > -1 : 'All');
    });
    this.yachts = result1;
    $('.loader_container_fav').hide();
  }

// tslint:disable-next-line:typedef
  myarrayContainsEating(yacht: any, facility: any) {
    return yacht.some((item: { name: any; }) => item.name === facility); // Check if any "eating" property is equal to food
 }

 // tslint:disable-next-line:typedef
 removeFavYacht($event: any){
  const removeId = $event.target.parentElement.attributes['data-id'].value;
  const index: number = this.idsArray.indexOf(removeId);
  if (index !== -1) {
     this.idsArray.splice(index, 1);
    }
  if (this.idsArray.length > 0){
    this.getFilterData();
    this.router.navigate([], {
    relativeTo: this.route,
    queryParams: { ids: this.idsArray.toString() },
    queryParamsHandling: 'merge'
    });
  }
  else{
    this.router.navigate(['/']);
  }
 }

 // tslint:disable-next-line:typedef
 sortByPrice($event: any){
  // tslint:disable-next-line:no-shadowed-variable
  this.yachts.sort((element: { DiscountedPrice: number }, element2: {DiscountedPrice: number }  ) => {
    // Ascending: first age less than the previous
    return (  $event.target.value === 'asc' && $event.target.value !== '') ?
    (element.DiscountedPrice - element2.DiscountedPrice) : (element2.DiscountedPrice - element.DiscountedPrice)  ;
  });
}

// tslint:disable-next-line:typedef
getCountryFlag($country: any){
  switch ($country) {
    case 'Croatia': {
        return 'hr';
    }
    case 'Greece': {
      return 'gr';
    }
    case 'Turkey': {
      return 'tr';
    }
    case 'Italy': {
      return 'it';
    }
    case 'Spain': {
      return 'es';
    }
    default: {
      return 'hr';
    }
 }
}


 // tslint:disable-next-line:typedef
 getSkipperText($skipper: any){
  switch ($skipper) {
    case 'Skipper Required': {
        return 'Including skipper';
    }
    case 'Host Required': {
      return 'Including host';
    }
    case 'Skipper and Host Required': {
      return 'Including crew';
    }
    default: {
      return 'Yacht  only price';
    }
 }
}

 // tslint:disable-next-line:typedef
 numberWithCommas($number: number) {
  const parts = $number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

}
