import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  showHeaderFooter: boolean = true;
  constructor(public router: Router) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.router.events.subscribe((event: any) => {
      if (
        event instanceof NavigationStart &&
        event.url
      ) {
        if((event.url.indexOf('/list') > -1 )) {
          this.showHeaderFooter = false;
        } else {
          this.showHeaderFooter = true;
        }
      } else {
        // this.showHeaderFooter = true;
      }
    });
  }
}

