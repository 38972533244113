import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Yachts } from '../../yachts';
import { ApicallService } from '../../apicall.service';
import { FormGroup, FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import { element } from 'protractor';
import { NouisliderComponent } from 'ng2-nouislider';
declare var $: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit {
  domainName = 'https://dev.seamaster.co.uk';
  isLoad = false;
  isInit = true;
  objectKeys = Object.keys;
  selectedRegionsItemsList: any = [];
  selectedItemsList: any = [];
  selectedfacilityItemsList: any = [];
  checkedIDs = Array();
  favIds = Array();
  strfavIds: any = '';
  checkedboatIDs = Array();
  yachts = [];
  yacht: any = {};
  response: any = {};
  result = '';
  strcheckedIDs: any = {};
  checkedislandsIDs: any = {};
  strboatcheckedIDs: any = '';
  checkedfacilityIDs = Array();
  strfacilitycheckedIDs: any = '';
  regionIDs = Array();
  strregioncheckedIDs: any = '';
  showresults: any = {};
  regionData: any = [];
  itemList: any = [];
  selectedItems: any = [];
  settings: any = {};
  location = [
    {
      id: 'checkboxesgreece',
      cuid: 'greece',
      name: 'Greece',
      code: 'gr',
      regions: [
        { id: 'athens', itemName: 'Athens', category: 'Saronic Island' },
        {
          id: 'saronic_lavrion',
          itemName: 'Lavrion',
          category: 'Saronic Island',
        },
        { id: 'corfu', itemName: 'Corfu', category: 'Ionian Islands' },
        { id: 'lefkas', itemName: 'Lefkas', category: 'Ionian Islands' },
        { id: 'preveza', itemName: 'Preveza', category: 'Ionian Islands' },
        { id: 'kefalonia', itemName: 'Kefalonia', category: 'Ionian Islands' },
        {
          id: 'cyclades_lavrion',
          itemName: 'Lavrion',
          category: 'Cyclades Islands',
        },
        { id: 'mykonos', itemName: 'Mykonos', category: 'Cyclades Islands' },
        { id: 'paros', itemName: 'Paros', category: 'Cyclades Islands' },
        { id: 'kos', itemName: 'Kos', category: 'Dodecanese Islands' },
        { id: 'rhodes', itemName: 'Rhodes', category: 'Dodecanese Islands' },
        { id: 'volos', itemName: 'Volos', category: 'Sporades Islands' },
        { id: 'skiathos', itemName: 'Skiathos', category: 'Sporades Islands' },
      ],
    },
    {
      id: 'checkboxescroatia',
      cuid: 'croatia',
      name: 'Croatia',
      code: 'hr',
      regions: [
        { id: 'split', itemName: 'Split', category: 'Dalmatian Islands' },
        { id: 'trogir', itemName: 'Trogir', category: 'Dalmatian Islands' },
        {
          id: 'dubrovnik',
          itemName: 'Dubrovnik',
          category: 'Dalmatian Islands',
        },
        {
          id: 'solta-island',
          itemName: 'Solta Island',
          category: 'Dalmatian Islands',
        },
        {
          id: 'dalmatian_rogoznica',
          itemName: 'Rogoznica',
          category: 'Dalmatian Islands',
        },
        {
          id: 'primosten',
          itemName: 'Primosten',
          category: 'Dalmatian Islands',
        },
        {
          id: 'kornati_rogoznica',
          itemName: 'Rogoznica',
          category: 'Kornati Islands',
        },
        { id: 'kornati_zadar', itemName: 'Zadar', category: 'Kornati Islands' },
        { id: 'biograd', itemName: 'Biograd', category: 'Kornati Islands' },
        { id: 'vodice', itemName: 'Vodice', category: 'Kornati Islands' },
        { id: 'sibenik', itemName: 'Sibenik', category: 'Kornati Islands' },
        { id: 'primosten', itemName: 'Primosten', category: 'Kornati Islands' },
        { id: 'pula', itemName: 'Pula', category: 'Kvarner Islands' },
        { id: 'kvarner_zadar', itemName: 'Zadar', category: 'Kvarner Islands' },
      ],
    },
    {
      id: 'checkboxesturkey',
      cuid: 'turkey',
      name: 'Turkey',
      code: 'tr',
      regions: [
        { id: 'fethiye', itemName: 'Fethiye', category: 'Dalaman Area' },
        { id: 'gocek', itemName: 'Gocek', category: 'Dalaman Area' },
        { id: 'marmaris', itemName: 'Marmaris', category: 'Dalaman Area' },
        { id: 'orhaniye', itemName: 'Orhaniye', category: 'Dalaman Area' },
      ],
    },
    {
      id: 'checkboxesitaly',
      cuid: 'italy',
      name: 'Italy',
      code: 'it',
      regions: [
        { id: 'palermo', itemName: 'Palermo', category: 'Sicily' },
        { id: 'orlando', itemName: "Capo d'Orlando", category: 'Sicily' },
        { id: 'portorosa', itemName: 'Portorosa', category: 'Sicily' },
        { id: 'milazzo', itemName: 'Milazzo', category: 'Sicily' },
        { id: 'portisco', itemName: 'Portisco', category: 'Sardinia' },
        { id: 'olbia', itemName: 'Olbia', category: 'Sardinia' },
        { id: 'cannigione', itemName: 'Cannigione', category: 'Sardinia' },
        {
          id: 'castiglioncello',
          itemName: 'Castiglioncello',
          category: 'Tuscany',
        },
        { id: 'follonica', itemName: 'Follonica', category: 'Tuscany' },
        { id: 'piombino', itemName: 'Piombino', category: 'Tuscany' },
        { id: 'salerno', itemName: 'Salerno', category: 'Amalfi Coast' },
        { id: 'naples', itemName: 'Naples', category: 'Amalfi Coast' },
        { id: 'procida', itemName: 'Procida', category: 'Amalfi Coast' },
      ],
    },
    {
      id: 'checkboxesspain',
      cuid: 'spain',
      name: 'Spain',
      code: 'es',
      regions: [
        {
          id: 'ibiza-san-antonio',
          itemName: 'Ibiza San Antonio',
          category: 'Balearic Islands',
        },
        {
          id: 'majorca-palma',
          itemName: 'Majorca - Palma',
          category: 'Balearic Islands',
        },
        {
          id: 'majorca-portocolom',
          itemName: 'Majorca - Portocolom',
          category: 'Balearic Islands',
        },
      ],
    },
  ];
  boatTypes = [
    {
      id: 'gulet',
      label: 'Gulet',
      isChecked: false,
    },
    {
      id: 'motoryacht',
      label: 'Motoryacht',
      isChecked: false,
    },
    {
      id: 'monohull',
      label: 'Monohull',
      isChecked: false,
    },
    {
      id: 'catamaran',
      label: 'Catamaran',
      isChecked: false,
    },
  ];
  facilityTypes = [
    {
      id: 'air-condition',
      label: 'Air condition',
      isChecked: false,
    },
    {
      id: 'autopilot',
      label: 'Autopilot',
      isChecked: false,
    },
    {
      id: 'generator',
      label: 'Generator',
      isChecked: false,
    },
    {
      id: 'bow-thruster',
      label: 'Bow thruster',
      isChecked: false,
    },
  ];
  regions: any = '';
  startDate: any = '';
  endDate: any = '';
  type: any = '';
  inputBoatLength: any = [];
  inputBoatAge: any = [];
  inputBoatCabin: any = [];
  inputBoatToilet: any = [];
  inputBoatBudget: any = [];
  minboatrange = 4;
  maxboatrange = 33;
  minboatagerange = 1942;
  maxboatagerange = 2021;
  minboatcabinrange = 1;
  maxboatcabinrange = 12;
  minboattoiletrange = 1;
  maxboattoiletrange = 8;
  minboatpricerange = 100;
  maxboatpricerange = 300000;
  minMaxDate: any = '';
  minboatlen: any = '';
  maxboatlen: any = '';
  minboatage: any = '';
  maxboatage: any = '';
  mincabin: any = '';
  maxcabin: any = '';
  mintoilet: any = '';
  maxtoilet: any = '';
  minprice: any = '';
  maxprice: any = '';
  sortBy: any = '';
  color = 'red';
  constructor(
    public http: HttpClient,
    private apiService: ApicallService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      // console.log(this.objectKeys(params).length);
      this.yacht = {
        destination: params.destination ? params.destination : 'Croatia',
        // tslint:disable-next-line:no-unused-expression
        regions: params.regions
          ? params.regions
          : 'Split,Trogir,Dubrovnik,Solta Island,Rogoznica,Zadar,Biograd,Vodice,Sibenik,Primosten,Pula',
        dateValue: params.dateValue
          ? params.dateValue
          : moment().isoWeekday(6).format('YYYY-MM-DD'),
        dateEndValue: params.dateEndValue
          ? params.dateEndValue
          : moment().isoWeekday(6).add('days', 28).format('YYYY-MM-DD'),
        noOfPeople: params.noOfPeople ? params.noOfPeople : '2',
        skipper: params.skipper ? params.skipper : 'Boat only',
      };
      this.strboatcheckedIDs = params.type ? params.type : '';
      if (this.strboatcheckedIDs !== '') {
        this.checkedboatIDs = this.strboatcheckedIDs.split(',');
      } else {
        $('#all_boats').prop('checked', true);
      }
      this.minboatlen = params.minboatlen ? params.minboatlen : '';
      this.maxboatlen = params.maxboatlen ? params.maxboatlen : '';
      if (this.minboatlen !== '' && this.maxboatlen !== '') {
        this.inputBoatLength = [
          Number(this.minboatlen),
          Number(this.maxboatlen),
        ];
      } else {
        this.inputBoatLength = [
          Number(this.minboatrange),
          Number(this.maxboatrange),
        ];
      }
      this.minboatage = params.minboatage ? params.minboatage : '';
      this.maxboatage = params.maxboatage ? params.maxboatage : '';
      if (this.minboatage !== '' && this.maxboatage !== '') {
        this.inputBoatAge = [Number(this.minboatage), Number(this.maxboatage)];
      } else {
        this.inputBoatAge = [
          Number(this.minboatagerange),
          Number(this.maxboatagerange),
        ];
      }
      this.mincabin = params.mincabin ? params.mincabin : '';
      this.maxcabin = params.maxcabin ? params.maxcabin : '';
      if (this.mincabin !== '' && this.maxcabin !== '') {
        this.inputBoatCabin = [Number(this.mincabin), Number(this.maxcabin)];
      } else {
        this.inputBoatCabin = [
          Number(this.minboatcabinrange),
          Number(this.maxboatcabinrange),
        ];
      }
      this.mintoilet = params.mintoilet ? params.mintoilet : '';
      this.maxtoilet = params.maxtoilet ? params.maxtoilet : '';
      if (this.mintoilet !== '' && this.maxtoilet !== '') {
        this.inputBoatToilet = [Number(this.mintoilet), Number(this.maxtoilet)];
      } else {
        this.inputBoatToilet = [
          Number(this.minboattoiletrange),
          Number(this.maxboattoiletrange),
        ];
      }
      this.minprice = params.minprice ? params.minprice : '';
      this.maxprice = params.maxprice ? params.maxprice : '';
      if (this.minprice !== '' && this.maxprice !== '') {
        this.inputBoatBudget = [Number(this.minprice), Number(this.maxprice)];
      } else {
        this.inputBoatBudget = [
          Number(this.minboatpricerange),
          Number(this.maxboatpricerange),
        ];
      }
      this.strfacilitycheckedIDs = params.facility ? params.facility : '';
      if (this.strfacilitycheckedIDs !== '') {
        this.checkedfacilityIDs = this.strfacilitycheckedIDs.split(',');
      }
      this.sortBy = params.sortBy ? params.sortBy : 'asc';
    });
  }
  // @ViewChild('monthlyCostSlider') public monthlyCostSlider!: NouisliderComponent;
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    setTimeout(() => {
      this.getAllYachts();
      // if (this.monthlyCostSlider.slider){
      //   this.monthlyCostSlider.slider.updateOptions({
      //     range: { min: this.minboatagerange, max: this.maxboatagerange } });
      // }
      this.showresults = {
        destination: this.yacht.destination,
        daterange:
          moment(this.yacht.dateValue).format('Do') +
          ' - ' +
          moment(this.yacht.dateEndValue).format('Do MMMM YYYY'),
      };
      this.fetchSelectedItems();
      this.fetchSelectedBoat();
      this.fetchSelectedFacility();
      this.minMaxDate =
        moment(this.yacht.dateValue).format('D MMMM') +
        ' - ' +
        moment(this.yacht.dateEndValue).format('D MMMM');
      const today = new Date();
      $('#cstm-datepicker').daterangepicker(
        {
          startDate: moment(this.yacht.dateValue).format('D MMMM'),
          endDate: moment(this.yacht.dateEndValue).format('D MMMM'),
          minDate: today,
          maxSpan: {
            days: 28,
          },
          locale: {
            monthNames: moment.months(),
            format: 'D MMMM',
            separator: ' - ',
          },
          isInvalidDate: (date: any) => {
            if (date.day() === 6) {
              return false;
            } else {
              return true;
            }
          },
        },
        (start: any, end: any, label: any) => {
          // console.log('A new date selection was made: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
          this.yacht.dateValue = start.format('YYYY-MM-DD');
          this.yacht.dateEndValue = end.format('YYYY-MM-DD');
          // console.log(this.yacht);
        }
      );
    }, 2000);
    // const dayINeed = 6; // for Thursday
    // const today = moment().isoWeekday();

    // if (today <= dayINeed) {
    //   const check = moment().isoWeekday(dayINeed);
    //   console.log(check);
    // }
    $('.yacht_container').hide();
    // $('#region').click(() => {
    //   const id = $('#country option:selected').attr('id');
    //   $('#checkboxes' + id + ' ').toggleClass('show');
    // });
    $('.load_more').click(() => {
      const sizeli = $('.visibleyatchs').length;
      const totalShown = $('.yachts_enable_for_now').length;
      const loaddivval = totalShown + 10 <= sizeli ? totalShown + 10 : sizeli;
      $('.visibleyatchs:lt(' + loaddivval + ')').addClass(
        'yachts_enable_for_now'
      );
      const remainVal = sizeli - totalShown;
      console.log(remainVal);
      if (remainVal < 10) {
        $('.load_more').hide();
      }
    });
    $('#submit').click(() => {
      // this.isLoad = false;
      // $('.yacht_container').hide();
      // $('.loader_container').show();
    });
    //   $('html').click((e: any)  => {
    //   if ( !$(e.target).hasClass('region-s'))
    //   {
    //     if ($('.region-select').hasClass('show')){
    //         $('.region-select').removeClass('show');
    //   }
    // }
    // });

    this.loadDataSet1();
    this.settings = {
      singleSelection: false,
      text: 'Select Regions',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Search Regions',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      groupBy: 'category',
    };

    $('#all_boats').on('change', () => {
      $('input.boat_types').not(this).prop('checked', false);
    });

    // $('.dropdown-menu').on('click', () => {
    // $(this).target.classList.add('classCst');
    // });
  }

  // tslint:disable-next-line:typedef
  // clickedFilter($event: any){
  //   // alert($event.target);
  //   // console.log($event.target);
  //   $('li.dropdown').removeClass('active_filter');
  //   $event.target.parentElement.classList.add('active_filter');
  // }

  // tslint:disable-next-line:typedef
  loadDataSet1() {
    this.selectedItems = [];
    this.location.filter((value, index) => {
      value.regions.forEach((value2) => {
        if (this.yacht.regions.indexOf(value2.itemName) > -1) {
          this.selectedItems.push(value2);
        }
      });
    });
    this.itemList = [];
    this.location.filter((value, index) => {
      value.regions.forEach((value2) => {
        if (value.name === this.yacht.destination) {
          this.itemList.push(value2);
        }
      });
    });
    $('.dropdown-list').attr('id', this.yacht.destination + '-list');
  }
  // tslint:disable-next-line:typedef
  loadDataSet2($event: any) {
    $('.dropdown-list').attr('id', $event.target.value + '-list');
    this.selectedItems = [];
    this.itemList = [];
    this.location.filter((value, index) => {
      value.regions.forEach((value2) => {
        if (value.name === $event.target.value) {
          this.itemList.push(value2);
        }
      });
    });
  }

  // tslint:disable-next-line:typedef
  onItemSelect(item: any) {}
  // tslint:disable-next-line:typedef
  OnItemDeSelect(item: any) {}
  // tslint:disable-next-line:typedef
  onSelectAll(items: any) {}
  // tslint:disable-next-line:typedef
  onDeSelectAll(items: any) {}

  // tslint:disable-next-line:typedef
  // getCheckboxchecked(){
  //  // $('div[id ^= checkboxes]').hide();
  //   $('div[id ^= checkboxes] input').prop('checked' , false);
  // }

  // tslint:disable-next-line:typedef
  showCalender() {
    $('#cstm-datepicker').click();
  }
  // tslint:disable-next-line:typedef
  getYachtsData() {
    // this.isInit = true;
    this.getRegionSelection();
    // this.getAllYachts();
    this.setYachtParam();
  }

  // tslint:disable-next-line:typedef
  setYachtParam() {
    this.showresults = {
      destination: this.yacht.destination,
      daterange:
        moment(this.startDate).format('Do') +
        ' - ' +
        moment(this.endDate).format('Do MMM YYYY'),
    };
    console.log('this.route:: ', this.route);
    const url = this.router.serializeUrl(this.router.createUrlTree(['/'], {
      // relativeTo: this.route,
      queryParams: this.yacht,
   }));
   window.open(url, '_blank');

    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: this.yacht,
    // }).then((result) => {
    //   console.log('result:: ', result);
    //   // window.open('/?aa=' + aa, '_blank');
    // });
    // this.router.navigate(['/'], {
    //   relativeTo: this.route,
    //   queryParams: this.yacht,
    // });
  }

  // tslint:disable-next-line:typedef
  getAllYachts() {
    this.apiService.getYachts(this.yacht).subscribe((res) => {
      console.log(JSON.parse(res));
      this.response = JSON.parse(res);
      this.getFilterData();
      if (this.isLoad === true) {
        this.loadMoreCustom();
        $('.loader_container').hide();
        $('.yacht_container').show();
      }
    });
    this.startDate = new Date(this.yacht.dateValue);
    this.endDate = new Date(this.yacht.dateEndValue);
  }
  // tslint:disable-next-line:typedef
  isEmpty(obj: { hasOwnProperty: (arg0: string) => any }) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  // tslint:disable-next-line:typedef
  getRegionSelection() {
    this.yacht.regions = '';
    this.checkedIDs = [];
    this.selectedItems.forEach((value: any) => {
      this.checkedIDs.push(value.itemName);
      this.strcheckedIDs = this.checkedIDs.toString();
      this.yacht.regions = this.strcheckedIDs;
    });
  }
  // tslint:disable-next-line:typedef
  changeSelectionIsland($event: any) {
    // this.yacht.regions = '';
    // this.location.forEach((value) => {
    // this.checkedIDs = [];
    // value.isLands.forEach((value2) => {
    //   if (value2.isChecked === true && value2.country === value.name) {
    //   value2.regions.filter((value3, index) => {
    //       this.checkedIDs.push(value3.label);
    //   });
    //   this.strcheckedIDs = this.checkedIDs.toString();
    //   this.yacht.regions = this.strcheckedIDs;
    //   }
    // });
    // });
  }

  // tslint:disable-next-line:typedef
  changeBoatTypeSelection($event: any) {
    this.fetchBoatIDs($event);
    $('#yacht-1').parent().addClass('active_filter');
    $('#all_boats').prop('checked', false);
    this.loadMoreCustom();
  }

  // tslint:disable-next-line:typedef
  changeFacilityTypeSelection() {
    this.fetchFacilityIDs();
    $('#more-filter').parent().addClass('active_filter');
    this.loadMoreCustom();
  }

  // tslint:disable-next-line:typedef
  fetchSelectedItems() {
    // const arrayRegionsEl  = this.yacht.regions.split(',');
    // this.selectedRegionsItemsList = this.location.forEach((value) => {
    //   value.isLands.forEach((value2) => {
    //     value2.regions.filter((value3, index) => {
    //       if (arrayRegionsEl.indexOf(value3.label) > -1){
    //         value3.isChecked = true;
    //         return true;
    //       }
    //       else{
    //         value3.isChecked = false;
    //         return false;
    //       }
    //     });
    //   });
    // });
  }

  // tslint:disable-next-line:typedef
  fetchSelectedBoat() {
    const arrayEl = this.strboatcheckedIDs.split(',');
    this.selectedItemsList = this.boatTypes.filter((value, index) => {
      if (arrayEl.indexOf(value.label) > -1) {
        value.isChecked = true;
        return true;
      } else {
        value.isChecked = false;
        return false;
      }
    });
    // console.log( this.selectedItemsList);
  }

  // tslint:disable-next-line:typedef
  fetchSelectedFacility() {
    const arrayFacilityEl = this.strfacilitycheckedIDs.split(',');
    this.selectedfacilityItemsList = this.facilityTypes.filter(
      (value, index) => {
        if (arrayFacilityEl.indexOf(value.label) > -1) {
          value.isChecked = true;
          return true;
        } else {
          value.isChecked = false;
          return false;
        }
      }
    );
  }

  // tslint:disable-next-line:typedef
  fetchAllBoats() {
    $('#yacht-1').parent().addClass('active_filter');
    this.strboatcheckedIDs = '';
    this.checkedboatIDs = [];
    this.boatTypes.forEach((value) => {
      this.checkedboatIDs.push(value.label);
    });
    this.strboatcheckedIDs = this.checkedboatIDs.toString();
    this.getFilterData();
  }

  // tslint:disable-next-line:typedef
  fetchBoatIDs($event: any) {
    // console.log($event.target.value);
    this.strboatcheckedIDs = '';
    this.checkedboatIDs = [];
    this.boatTypes.forEach((value, index) => {
      if (value.isChecked && value.label === $event.target.value) {
        this.checkedboatIDs.push(value.label);
      } else {
        value.isChecked = false;
      }
    });
    this.strboatcheckedIDs = this.checkedboatIDs.toString();
    this.getFilterData();
  }

  // tslint:disable-next-line:typedef
  fetchFacilityIDs() {
    this.strfacilitycheckedIDs = '';
    this.checkedfacilityIDs = [];
    this.facilityTypes.filter((value, index) => {
      if (value.isChecked) {
        this.checkedfacilityIDs.push(value.label);
      }
    });
    this.strfacilitycheckedIDs = this.checkedfacilityIDs.toString();
    this.getFilterData();
  }

  // tslint:disable-next-line:typedef
  getminmaxvalues() {
    const data = this.response.yachts;
    // console.log(data);
    // const uniqueBoatLength = [...new Set(data.map((item: {Boat_Length: number; }) => item.Boat_Length))];
    const max = (a: any, f: any) =>
      a.reduce((m: any, x: any) => (m[f] > x[f] ? m : x));
    const min = (a: any, f: any) =>
      a.reduce((m: any, x: any) => (m[f] < x[f] ? m : x));
    this.minboatrange = min(data, 'Boat_Length').Boat_Length;
    console.log(this.minboatrange);
    this.maxboatrange = max(data, 'Boat_Length').Boat_Length;
    console.log(this.maxboatrange);
    this.minboatagerange = min(data, 'Boat_Age').Boat_Age;
    this.maxboatagerange = max(data, 'Boat_Age').Boat_Age;
    this.minboatcabinrange = Number(min(data, 'Cabins').Cabins);
    this.maxboatcabinrange = Number(max(data, 'Cabins').Cabins);
    this.minboattoiletrange = min(data, 'Toilet').Toilet;
    this.maxboattoiletrange = max(data, 'Toilet').Toilet;
    this.minboatpricerange = min(data, 'DiscountedPrice').DiscountedPrice;
    this.maxboatpricerange = max(data, 'DiscountedPrice').DiscountedPrice;
  }
  // tslint:disable-next-line:typedef
  // getDateData($event: any) {
  //   const daterange = $event.target.value;
  //   console.log('rangedate', daterange);
  //   const datearr = daterange.split(' to ');
  //   const sdatearr = datearr[0];
  //   const edatearr = datearr[1];
  //   // console.log('sdate', sdatearr);
  //   // console.log('edate', edatearr);
  //   this.yacht.dateValue = sdatearr;
  //   this.yacht.dateEndValue = edatearr;
  //   console.log(this.yacht);
  // }

  // tslint:disable-next-line:typedef
  getBoatLength($event: any) {
    $('#boat-length').parent().addClass('active_filter');
    this.minboatlen = this.inputBoatLength[0];
    this.maxboatlen = this.inputBoatLength[1];
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatAge() {
    $('#boat-age').parent().addClass('active_filter');
    this.minboatage = this.inputBoatAge[0];
    this.maxboatage = this.inputBoatAge[1];
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatCabin() {
    $('#boat-cabin').parent().addClass('active_filter');
    this.mincabin = this.inputBoatCabin[0];
    this.maxcabin = this.inputBoatCabin[1];
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatToilet() {
    $('#boat-toilet').parent().addClass('active_filter');
    this.mintoilet = this.inputBoatToilet[0];
    this.maxtoilet = this.inputBoatToilet[1];
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatBudget() {
    $('#boat-budget').parent().addClass('active_filter');
    this.minprice = this.inputBoatBudget[0];
    this.maxprice = this.inputBoatBudget[1];
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getFilterData() {
    this.isLoad = false;
    // tslint:disable-next-line:no-shadowed-variable
    const result1 = this.response.yachts.filter(
      (element: {
        type: string;
        Boat_Length: number;
        Boat_Age: number;
        Cabins: number;
        Toilet: number;
        DiscountedPrice: number;
        Facilities: Array<any>;
      }) => {
        const facilityArr: any = [];
        element.Facilities.forEach((facility) => {
          facilityArr.push(facility.name);
        });
        // console.log(element.Facilities.Navigation);
        // tslint:disable-next-line:no-unused-expression
        return (
          (this.checkedboatIDs.length > 0
            ? this.checkedboatIDs.indexOf(element.type) !== -1
            : true) &&
          (this.minboatlen
            ? element.Boat_Length >= Number(this.minboatlen)
            : true) &&
          (this.maxboatlen
            ? element.Boat_Length <= Number(this.maxboatlen)
            : true) &&
          (this.minboatage
            ? element.Boat_Age >= Number(this.minboatage)
            : true) &&
          (this.maxboatage
            ? element.Boat_Age <= Number(this.maxboatage)
            : true) &&
          (this.mincabin ? element.Cabins >= Number(this.mincabin) : true) &&
          (this.maxcabin ? element.Cabins <= Number(this.maxcabin) : true) &&
          (this.mintoilet ? element.Toilet >= Number(this.mintoilet) : true) &&
          (this.maxtoilet ? element.Toilet <= Number(this.maxtoilet) : true) &&
          (this.minprice
            ? element.DiscountedPrice >= Number(this.minprice)
            : true) &&
          (this.maxprice
            ? element.DiscountedPrice <= Number(this.maxprice)
            : true) &&
          (this.checkedfacilityIDs.length > 0 && facilityArr.length > 0
            ? this.checkedfacilityIDs.every(
                (elem) => facilityArr.indexOf(elem) > -1
              )
            : this.checkedfacilityIDs.length > 0 && facilityArr.length === 0
            ? false
            : true)
        );
      }
    );
    result1.sort(
      (
        element1: { DiscountedPrice: number },
        element2: { DiscountedPrice: number }
      ) => {
        // Ascending: first price less than the previous
        return this.sortBy === 'asc'
          ? element1.DiscountedPrice - element2.DiscountedPrice
          : element2.DiscountedPrice - element1.DiscountedPrice;
      }
    );
    this.yachts = result1;
    if (this.isInit === true) {
      this.getminmaxvalues();
      this.isInit = false;
    }
    this.isLoad = true;
    console.log(this.yachts);
    console.log(this.isLoad);
    localStorage.setItem('dataYachts', JSON.stringify(this.yachts));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        destination: this.yacht.destination,
        regions: this.yacht.regions,
        dateValue: this.yacht.dateValue,
        dateEndValue: this.yacht.dateEndValue,
        noOfPeople: this.yacht.noOfPeople,
        skipper: this.yacht.skipper,
        type: this.strboatcheckedIDs,
        minboatlen: this.minboatlen,
        maxboatlen: this.maxboatlen,
        minboatage: this.minboatage,
        maxboatage: this.maxboatage,
        mincabin: this.mincabin,
        maxcabin: this.maxcabin,
        mintoilet: this.mintoilet,
        maxtoilet: this.maxtoilet,
        minprice: this.minprice,
        maxprice: this.maxprice,
        facility: this.strfacilitycheckedIDs,
        sortBy: this.sortBy,
      },
      queryParamsHandling: 'merge',
    });
  }
  // tslint:disable-next-line:typedef
  sortByPrice() {
    const sortResult = this.yachts;
    sortResult.sort(
      (
        element1: { DiscountedPrice: number },
        element2: { DiscountedPrice: number }
      ) => {
        // Ascending: first price less than the previous
        return this.sortBy === 'asc'
          ? element1.DiscountedPrice - element2.DiscountedPrice
          : element2.DiscountedPrice - element1.DiscountedPrice;
      }
    );
    this.yachts = sortResult;
    this.loadMoreCustom();
  }

  // tslint:disable-next-line:typedef
  showMore() {
    if (this.isLoad === true) {
      this.loadMoreCustom();
    }
  }
  // tslint:disable-next-line:typedef
  loadMoreCustom() {
    $('.load_more').hide();
    setTimeout(() => {
      // $('li.dropdown').removeClass('active_filter');
      $('.visibleyatchs').removeClass('yachts_enable_for_now');
      const loaddivval = 10;
      const sizeli = $('.visibleyatchs').length;
      $('.visibleyatchs:lt(' + loaddivval + ')').addClass(
        'yachts_enable_for_now'
      );
      if (sizeli > 10) {
        $('.load_more').show();
      } else {
        $('.load_more').hide();
      }
    }, 1);
  }

  // Begin:: like unlike functionality
  // tslint:disable-next-line:typedef
  likeUnlike($event: any) {
    this.favIds = [];
    const item = $event.target.parentElement;
    item.classList.toggle('heart-checked');
    // tslint:disable-next-line:no-shadowed-variable
    const heart = $('.heart');
    // tslint:disable-next-line:no-shadowed-variable
    $('.heart').each((index: any, element: any) => {
      if (element.classList.contains('heart-checked')) {
        const id = element.attributes['data-id'].value;
        this.favIds.push(id);
      }
    });
    this.strfavIds = this.favIds.toString();
  }
  // End:: like unlike functionality

  // tslint:disable-next-line:typedef
  getCountryFlag($country: any) {
    switch ($country) {
      case 'Croatia': {
        return 'hr';
      }
      case 'Greece': {
        return 'gr';
      }
      case 'Turkey': {
        return 'tr';
      }
      case 'Italy': {
        return 'it';
      }
      case 'Spain': {
        return 'es';
      }
      default: {
        return 'hr';
      }
    }
  }

  // tslint:disable-next-line:typedef
  getSkipperText($skipper: any) {
    switch ($skipper) {
      case 'Skipper Required': {
        return 'Including skipper';
      }
      case 'Host Required': {
        return 'Including host';
      }
      case 'Skipper and Host Required': {
        return 'Including crew';
      }
      default: {
        return 'Yacht  only price';
      }
    }
  }

  // tslint:disable-next-line:typedef
  numberWithCommas($number: number) {
    const parts = $number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  // tslint:disable-next-line:typedef
  getRegionsByLocation() {
    // const country = $('#country').val();
    // this.regionData = this.location.forEach((value) => {
    //   value.isLands.filter((value2) => {
    //     if (value2.country === country){
    //       return true;
    //     }
    //     else{
    //       return false;
    //     }
    //   });
    // });
    // console.log(this.regionData);
  }
}
