import { Component, OnInit,   ViewEncapsulation, ChangeDetectorRef, } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Router, ActivatedRoute } from '@angular/router';
// import { ApicallService } from '../../apicall.service';
// import { SharedService } from 'src/app/shared.service';
// import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  domainName = 'https://dev.seamaster.co.uk';
  constructor() {
  }
  ngOnInit(): void {}
}
