import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Yachts } from '../../yachts';
import { Subscription } from 'rxjs';
import { ApicallService } from '../../apicall.service';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import { element } from 'protractor';
import { NouisliderComponent } from 'ng2-nouislider';
import { SharedService } from 'src/app/shared.service';
import { CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { copyFileSync } from 'fs';
import { setInterval } from 'timers';

declare var $: any;
// tslint:disable-next-line:no-namespace

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ListingComponent implements OnInit {
  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  educationalDetails!: FormGroup;
  submitted = false;
  personal_step = false;
  address_step = false;
  education_step = false;
  step = 1;
  // Multistep form items
  clickEventSubscription: Subscription;
  domainName = 'https://www.seamaster.co.uk/';
  isLoad = false;
  isInit = true;
  objectKeys = Object.keys;
  selectedRegionsItemsList: any = [];
  selectedItemsList: any = [];
  selectedfacilityItemsList: any = [];
  checkedIDs = Array();
  favIds = Array();
  strfavIds: any = '';
  checkedboatIDs = Array();
  yachts = [];
  yacht: any = {};
  enquery: any = {};
  response: any = {};
  result = '';
  strcheckedIDs: any = {};
  checkedislandsIDs: any = {};
  strboatcheckedIDs: any = '';
  checkedfacilityIDs = Array();
  strfacilitycheckedIDs: any = '';
  regionIDs = Array();
  strregioncheckedIDs: any = '';
  showresults: any = {};
  showresultsEnquiry: any = {};
  regionData: any = [];
  itemList: any = [];
  selectedItems: any = [];
  settings: any = {};
  location = [
    {
      id: 'checkboxesgreece',
      cuid: 'greece',
      name: 'Greece',
      code: 'gr',
      regions: [
        { id: 'athens', itemName: 'Athens', category: 'Saronic Island' },
        {
          id: 'saronic_lavrion',
          itemName: 'Lavrion',
          category: 'Saronic Island',
        },
        { id: 'corfu', itemName: 'Corfu', category: 'Ionian Islands' },
        { id: 'lefkas', itemName: 'Lefkas', category: 'Ionian Islands' },
        { id: 'preveza', itemName: 'Preveza', category: 'Ionian Islands' },
        { id: 'kefalonia', itemName: 'Kefalonia', category: 'Ionian Islands' },
        {
          id: 'cyclades_lavrion',
          itemName: 'Lavrion',
          category: 'Cyclades Islands',
        },
        { id: 'mykonos', itemName: 'Mykonos', category: 'Cyclades Islands' },
        { id: 'paros', itemName: 'Paros', category: 'Cyclades Islands' },
        { id: 'kos', itemName: 'Kos', category: 'Dodecanese Islands' },
        { id: 'rhodes', itemName: 'Rhodes', category: 'Dodecanese Islands' },
        { id: 'volos', itemName: 'Volos', category: 'Sporades Islands' },
        { id: 'skiathos', itemName: 'Skiathos', category: 'Sporades Islands' },
      ],
    },
    {
      id: 'checkboxescroatia',
      cuid: 'croatia',
      name: 'Croatia',
      code: 'hr',
      regions: [
        { id: 'split', itemName: 'Split', category: 'Dalmatian Islands' },
        { id: 'trogir', itemName: 'Trogir', category: 'Dalmatian Islands' },
        {
          id: 'dubrovnik',
          itemName: 'Dubrovnik',
          category: 'Dalmatian Islands',
        },
        {
          id: 'solta-island',
          itemName: 'Solta Island',
          category: 'Dalmatian Islands',
        },
        {
          id: 'dalmatian_rogoznica',
          itemName: 'Rogoznica',
          category: 'Dalmatian Islands',
        },
        {
          id: 'primosten',
          itemName: 'Primosten',
          category: 'Dalmatian Islands',
        },
        {
          id: 'kornati_rogoznica',
          itemName: 'Rogoznica',
          category: 'Kornati Islands',
        },
        { id: 'kornati_zadar', itemName: 'Zadar', category: 'Kornati Islands' },
        { id: 'biograd', itemName: 'Biograd', category: 'Kornati Islands' },
        { id: 'vodice', itemName: 'Vodice', category: 'Kornati Islands' },
        { id: 'sibenik', itemName: 'Sibenik', category: 'Kornati Islands' },
        { id: 'primosten', itemName: 'Primosten', category: 'Kornati Islands' },
        { id: 'pula', itemName: 'Pula', category: 'Kvarner Islands' },
        { id: 'kvarner_zadar', itemName: 'Zadar', category: 'Kvarner Islands' },
      ],
    },
    {
      id: 'checkboxesturkey',
      cuid: 'turkey',
      name: 'Turkey',
      code: 'tr',
      regions: [
        { id: 'fethiye', itemName: 'Fethiye', category: 'Dalaman Area' },
        { id: 'gocek', itemName: 'Gocek', category: 'Dalaman Area' },
        { id: 'marmaris', itemName: 'Marmaris', category: 'Dalaman Area' },
        { id: 'orhaniye', itemName: 'Orhaniye', category: 'Dalaman Area' },
      ],
    },
    {
      id: 'checkboxesitaly',
      cuid: 'italy',
      name: 'Italy',
      code: 'it',
      regions: [
        { id: 'palermo', itemName: 'Palermo', category: 'Sicily' },
        { id: 'orlando', itemName: "Capo d'Orlando", category: 'Sicily' },
        { id: 'portorosa', itemName: 'Portorosa', category: 'Sicily' },
        { id: 'milazzo', itemName: 'Milazzo', category: 'Sicily' },
        { id: 'portisco', itemName: 'Portisco', category: 'Sardinia' },
        { id: 'olbia', itemName: 'Olbia', category: 'Sardinia' },
        { id: 'cannigione', itemName: 'Cannigione', category: 'Sardinia' },
        {
          id: 'castiglioncello',
          itemName: 'Castiglioncello',
          category: 'Tuscany',
        },
        { id: 'follonica', itemName: 'Follonica', category: 'Tuscany' },
        { id: 'piombino', itemName: 'Piombino', category: 'Tuscany' },
        { id: 'salerno', itemName: 'Salerno', category: 'Amalfi Coast' },
        { id: 'naples', itemName: 'Naples', category: 'Amalfi Coast' },
        { id: 'procida', itemName: 'Procida', category: 'Amalfi Coast' },
      ],
    },
    {
      id: 'checkboxesspain',
      cuid: 'spain',
      name: 'Spain',
      code: 'es',
      regions: [
        {
          id: 'ibiza-san-antonio',
          itemName: 'Ibiza San Antonio',
          category: 'Balearic Islands',
        },
        {
          id: 'majorca-palma',
          itemName: 'Majorca - Palma',
          category: 'Balearic Islands',
        },
        {
          id: 'majorca-portocolom',
          itemName: 'Majorca - Portocolom',
          category: 'Balearic Islands',
        },
      ],
    },
  ];
  boatTypes = [
    {
      id: 'Sailing yacht',
      label: 'Sailing yacht',
      isChecked: false,
    },
    {
      id: 'Catamaran',
      label: 'Catamaran',
      isChecked: false,
    },
    // {
    //   id: 'Motorboat',
    //   label: 'Motorboat',
    //   isChecked: false,
    // },
    {
      id: 'Motor yacht',
      label: 'Motor yacht',
      isChecked: false,
    },
    {
      id: 'Gulet',
      label: 'Gulet',
      isChecked: false,
    },
  ];
  facilityTypes = [
    {
      id: 'air-condition',
      label: 'Air condition',
      isChecked: false,
    },
    {
      id: 'autopilot',
      label: 'Autopilot',
      isChecked: false,
    },
    {
      id: 'generator',
      label: 'Generator',
      isChecked: false,
    },
    {
      id: 'bow-thruster',
      label: 'Bow thruster',
      isChecked: false,
    },
  ];
  regions: any = '';
  startDate: any = '';
  endDate: any = '';
  type: any = '';
  inputBoatLength: any = [];
  inputBoatAge: any = [];
  inputBoatCabin: any = [];
  inputBoatToilet: any = [];
  inputBoatBudget: any = [];
  minboatrange = 4;
  maxboatrange = 33;
  minboatagerange = 1942;
  maxboatagerange = 2021;
  minboatcabinrange = 1;
  maxboatcabinrange = 12;
  minboattoiletrange = 1;
  maxboattoiletrange = 8;
  minboatpricerange = 100;
  maxboatpricerange = 300000;
  minMaxDate: any = '';
  minboatlen: any = '';
  maxboatlen: any = '';
  minboatage: any = '';
  maxboatage: any = '';
  mincabin: any = '';
  maxcabin: any = '';
  mintoilet: any = '';
  maxtoilet: any = '';
  minprice: any = '';
  maxprice: any = '';
  sortBy: any = '';
  color = 'red';
  adultguest: number = 0;
  childguest: number = 0;
  infantguest: number = 0;
  enquiryAdultguest: number = 0;
  enquiryChildguest: number = 0;
  enquiryInfantguest: number = 0;
  Boat_Type_Preference: any = '';
  Preferred_destination: any = '';
  Email: any = '';
  No_Of_Cabins: number = 0;
  No_of_people_sailing: any = '';
  Comment: any = '';
  First_Name: any = '';
  Select_Budget: any = '';
  Last_Name: any = '';
  Start_Date: any = '';
  End_Date: any = '';
  Crew_Required: any = '';
  No_Of_Toilets: any = '';
  Mobile: any = '';
  emailFirst_Name: any = '';
  emailLast_Name: any = '';
  emailPhone: any = '';
  emailApiEmail: any = '';
  
  separateDialCode = true;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}

  constructor(
    public http: HttpClient,
    private apiService: ApicallService,
    private router: Router,
    private route: ActivatedRoute,
    private changeRef: ChangeDetectorRef,
    private sharedservice: SharedService,
    private formBuilder: FormBuilder
  ) {
    this.clickEventSubscription = this.sharedservice
      .getClickEvent()
      .subscribe(() => {
        this.getYachtsData();
        // this.getEnqueryData();
      });
    this.route.queryParams.subscribe((params) => {
      console.log('PARAMS:: ', params, this.objectKeys(params).length);
      console.log('Params values', params);

      localStorage.setItem("adultguest", params.adultguest);
      localStorage.setItem("childguest", params.childguest);
      localStorage.setItem("infantguest", params.infantguest);
      
      if(params.adultguest){
        this.adultguest = parseInt(params.adultguest);
      }

      if(params.childguest){
        this.childguest = parseInt(params.childguest);
      }

      if(params.infantguest){
        this.infantguest = parseInt(params.infantguest);
      }
      var noOfPeopleVal = parseInt(params.adultguest ? params.adultguest : 0) + parseInt(params.childguest ? params.childguest : 0) +  parseInt(params.infantguest ? params.infantguest : 0);
      // console.log("noOfPeopleVal", noOfPeopleVal);
      this.yacht = {
        destination: params.destination ? params.destination : 'Croatia',
        // tslint:disable-next-line:no-unused-expression
        regions: params.regions
          ? params.regions
          : 'Split,Trogir,Dubrovnik,Solta Island,Rogoznica,Zadar,Biograd,Vodice,Sibenik,Primosten,Pula',
        dateValue: params.dateValue
          ? params.dateValue
          : moment().isoWeekday(6).format('YYYY-MM-DD'),
        dateEndValue: params.dateEndValue
          ? params.dateEndValue
          : moment().isoWeekday(6).add('days', 28).format('YYYY-MM-DD'),
        noOfPeople: noOfPeopleVal,
        skipper: params.skipper ? params.skipper : 'Boat only',
      };

      // ENQUIRY SET ELEMENTS
      // let No_of_people_sailing = parseInt(params.enquiryAdultguest) + parseInt(params.enquiryChildguest) +  parseInt(params.enquiryInfantguest);
      var No_of_people_sailingVal = parseInt(params.enquiryAdultguest ? params.enquiryAdultguest : 0) + parseInt(params.enquiryChildguest ? params.enquiryChildguest : 0) +  parseInt(params.enquiryInfantguest ? params.enquiryInfantguest : 0);
      console.log(No_of_people_sailingVal);
      this.enquery = {
        Boat_Type_Preference: params.Boat_Type_Preference,
        Preferred_destination: params.Preferred_destination,
        Email: params.Email,
        No_Of_Cabins: params.No_Of_Cabins,
        No_of_people_sailing: No_of_people_sailingVal,
        Comment: params.Comment,
        First_Name: params.First_Name,
        Select_Budget: params.Select_Budget,
        Last_Name: params.Last_Name,
        dateOfHolidays: params.Start_Date + params.End_Date,
        // End_Date: params.End_Date,
        Crew_Required: params.Crew_Required,
        No_Of_Toilets: params.No_Of_Toilets,
        Mobile: params.Mobile,
      };

      this.strboatcheckedIDs = params.type ? params.type : '';
      if (this.strboatcheckedIDs !== '') {
        this.checkedboatIDs = this.strboatcheckedIDs.split(',');
      } else {
        $('#all_boats').prop('checked', true);
      }
      this.minboatlen = params.minboatlen ? params.minboatlen : '';
      this.maxboatlen = params.maxboatlen ? params.maxboatlen : '';
      if (this.minboatlen !== '' && this.maxboatlen !== '') {
        // this.inputBoatLength = [
        //   Number(this.minboatlen),
        //   Number(this.maxboatlen),
        // ];
        this.inputBoatLength = params.inputBoatLength
          ? params.inputBoatLength
          : [Number(this.minboatlen), Number(this.maxboatlen)];
      } else {
        // this.inputBoatLength = [
        //   Number(this.minboatrange),
        //   Number(this.maxboatrange),
        // ];
        this.inputBoatLength = params.inputBoatLength
          ? params.inputBoatLength
          : [Number(this.minboatrange), Number(this.maxboatrange)];
      }
      this.minboatage = params.minboatage ? params.minboatage : '';
      this.maxboatage = params.maxboatage ? params.maxboatage : '';
      if (this.inputBoatAge.length) {
        this.minboatage = this.inputBoatAge[0];
        this.maxboatage = this.inputBoatAge[1];
      }
      if (this.minboatage !== '' && this.maxboatage !== '') {
        // this.inputBoatAge = [Number(this.minboatage), Number(this.maxboatage)];
        this.inputBoatAge = params.inputBoatAge
          ? params.inputBoatAge
          : [Number(this.minboatage), Number(this.maxboatage)];
      } else {
        // this.inputBoatAge = [
        //   Number(this.minboatagerange),
        //   Number(this.maxboatagerange),
        // ];
        this.inputBoatAge = params.inputBoatAge
          ? params.inputBoatAge
          : [Number(this.minboatagerange), Number(this.maxboatagerange)];
      }
      this.mincabin = params.mincabin ? Number(params.mincabin) : '';
      this.maxcabin = params.maxcabin ? Number(params.maxcabin) : '';
      if (this.mincabin !== '' && this.maxcabin !== '') {
        this.inputBoatCabin = params.inputBoatCabin
          ? params.inputBoatCabin
          : [Number(this.mincabin), Number(this.maxcabin)];
        // this.inputBoatCabin = [Number(this.mincabin), Number(this.maxcabin)];
      } else {
        // this.inputBoatCabin = [
        //   Number(this.minboatcabinrange),
        //   Number(this.maxboatcabinrange),
        // ];
        this.inputBoatCabin = params.inputBoatCabin
          ? params.inputBoatCabin
          : [Number(this.minboatcabinrange), Number(this.maxboatcabinrange)];
      }
      this.mintoilet = params.mintoilet ? params.mintoilet : '';
      this.maxtoilet = params.maxtoilet ? params.maxtoilet : '';
      if (this.mintoilet !== '' && this.maxtoilet !== '') {
        // this.inputBoatToilet = [Number(this.mintoilet), Number(this.maxtoilet)];
        this.inputBoatToilet = params.inputBoatToilet
          ? params.inputBoatToilet
          : [Number(this.mintoilet), Number(this.maxtoilet)];
      } else {
        // this.inputBoatToilet = [
        //   Number(this.minboattoiletrange),
        //   Number(this.maxboattoiletrange),
        // ];
        this.inputBoatToilet = params.inputBoatToilet
          ? params.inputBoatToilet
          : [Number(this.minboattoiletrange), Number(this.maxboattoiletrange)];
      }
      this.minprice = params.minprice ? params.minprice : '';
      this.maxprice = params.maxprice ? params.maxprice : '';
      if (this.minprice !== '' && this.maxprice !== '') {
        // this.inputBoatBudget = [Number(this.minprice), Number(this.maxprice)];
        this.inputBoatBudget = params.inputBoatBudget
          ? params.inputBoatBudget
          : [Number(this.minprice), Number(this.maxprice)];
      } else {
        // this.inputBoatBudget = [
        //   Number(this.minboatpricerange),
        //   Number(this.maxboatpricerange),
        // ];
        this.inputBoatBudget = params.inputBoatBudget
          ? params.inputBoatBudget
          : [Number(this.minboatpricerange), Number(this.maxboatpricerange)];
      }
      // console.log(
      //   'Check here:: ',
      //   this.inputBoatLength,
      //   this.inputBoatAge,
      //   this.inputBoatCabin,
      //   this.inputBoatToilet,
      //   this.inputBoatBudget,
      //   this.mincabin,
      //   this.maxcabin
      // );

      this.strfacilitycheckedIDs = params.facility ? params.facility : '';
      if (this.strfacilitycheckedIDs !== '') {
        this.checkedfacilityIDs = this.strfacilitycheckedIDs.split(',');
      }
      this.sortBy = params.sortBy ? params.sortBy : 'asc';
    });
  }
  // @ViewChild('monthlyCostSlider') public monthlyCostSlider!: NouisliderComponent;
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    // // Multistep form validation start
    this.personalDetails = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      Mobile: ['', Validators.required],
    });

    this.addressDetails = this.formBuilder.group({
      No_of_people_sailing: [''],
      destination: [''],
      budget: [''],
      dateOfHolidays:['']
    });

    this.educationalDetails = this.formBuilder.group({
      No_Of_Cabins: [''],
      No_Of_Toilets: [''],
      Boat_Type_Preference: [''],
      Crew_Required: [''],
      Comment: ['']
    });
    // // Multistep form validation End
    this.showMenu();
    this.guestfunc();
    this.enquirygGuestfunc();
    setTimeout(() => {
      this.changeRef.detectChanges();

      this.getAllYachts();
      // if (this.monthlyCostSlider.slider){
      //   this.monthlyCostSlider.slider.updateOptions({
      //     range: { min: this.minboatagerange, max: this.maxboatagerange } });
      // }
      this.showresults = {
        destination: this.yacht.destination,
        daterange:
          moment(this.yacht.dateValue).format('Do') +
          ' - ' +
          moment(this.yacht.dateEndValue).format('Do MMMM YYYY'),
      };
      this.showresultsEnquiry = {
        daterange:
          moment(this.enquery.Start_Date).format('Do') +
          ' - ' +
          moment(this.enquery.End_Date).format('Do MMMM YYYY'),
      };
      this.fetchSelectedItems();
      this.fetchSelectedBoat();
      this.fetchSelectedFacility();
      this.minMaxDate =
        moment(this.yacht.dateValue).format('D MMMM') +
        ' - ' +
        moment(this.yacht.dateEndValue).format('D MMMM');
      console.log('minMaxDate:: ', this.minMaxDate);
      console.log(
        'this.yacht.dateValue:: ',
        this.yacht.dateValue,
        moment(this.yacht.dateValue).format('D MMMM YYYY')
      );
      console.log(
        'this.yacht.dateEndValue:: ',
        this.yacht.dateEndValue,
        moment(this.yacht.dateEndValue).format('D MMMM YYYY')
      );
      const today = new Date();
      $('#cstm-datepicker').daterangepicker(
        {
          startDate: moment(this.yacht.dateValue).format('D MMMM YYYY'),
          endDate: moment(this.yacht.dateEndValue).format('D MMMM YYYY'),
          minDate: today,
          maxSpan: {
            days: 28,
          },
          locale: {
            monthNames: moment.months(),
            format: 'D MMMM YYYY',
            separator: ' - ',
          },
          isInvalidDate: (date: any) => {
            if (date.day() === 6) {
              return false;
            } else {
              return true;
            }
          },
        },
        (start: any, end: any, label: any) => {
          // console.log('A new date selection was made: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
          this.yacht.dateValue = start.format('YYYY-MM-DD');
          this.yacht.dateEndValue = end.format('YYYY-MM-DD');
          this.minMaxDate =
            moment(this.yacht.dateValue).format('D MMMM') +
            ' - ' +
            moment(this.yacht.dateEndValue).format('D MMMM');
          // console.log(this.yacht);
        }
      );
      $('#cstm-datepicker').on(
        'apply.daterangepicker',
        (ev: any, picker: any) => {
          this.yacht.dateValue = picker.startDate.format('YYYY-MM-DD');
          this.yacht.dateEndValue = picker.endDate.format('YYYY-MM-DD');
          this.minMaxDate =
            moment(this.yacht.dateValue).format('D MMMM') +
            ' - ' +
            moment(this.yacht.dateEndValue).format('D MMMM');
        }
      );
    }, 2000);
    // const dayINeed = 6; // for Thursday
    // const today = moment().isoWeekday();

    // if (today <= dayINeed) {
    //   const check = moment().isoWeekday(dayINeed);
    //   console.log(check);
    // }
    $('.yacht_container').hide();
    // $('#region').click(() => {
    //   const id = $('#country option:selected').attr('id');
    //   $('#checkboxes' + id + ' ').toggleClass('show');
    // });
    $('.load_more').click(() => {
      const sizeli = $('.visibleyatchs').length;
      const totalShown = $('.yachts_enable_for_now').length;
      const loaddivval = totalShown + 10 <= sizeli ? totalShown + 10 : sizeli;
      $('.visibleyatchs:lt(' + loaddivval + ')').addClass(
        'yachts_enable_for_now'
      );
      const remainVal = sizeli - totalShown;
      console.log(remainVal);
      if (remainVal < 10) {
        $('.load_more').hide();
      }
    });
    $('#submit').click(() => {
      this.isLoad = false;
      $('.yacht_container').hide();
      $('.loader_container').show();
    });
    //   $('html').click((e: any)  => {
    //   if ( !$(e.target).hasClass('region-s'))
    //   {
    //     if ($('.region-select').hasClass('show')){
    //         $('.region-select').removeClass('show');
    //   }
    // }
    // });

    this.loadDataSet1();
    this.settings = {
      singleSelection: false,
      text: 'Select Regions',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Search Regions',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      groupBy: 'category',
    };

    $('#all_boats').on('change', () => {
      $('input.boat_types').not(this).prop('checked', false);
    });

    // $('.dropdown-menu').on('click', () => {
    // $(this).target.classList.add('classCst');
    // });

  }
  // count: number = 0;
  //   incremetcount(){
  //     this.count++
  //   }

  // tslint:disable-next-line:typedef
  // clickedFilter($event: any){
  //   // alert($event.target);
  //   // console.log($event.target);
  //   $('li.dropdown').removeClass('active_filter');
  //   $event.target.parentElement.classList.add('active_filter');
  // }

  // tslint:disable-next-line:typedef
  loadDataSet1() {
    this.selectedItems = [];
    this.location.filter((value, index) => {
      value.regions.forEach((value2) => {
        if (this.yacht.regions.indexOf(value2.itemName) > -1) {
          this.selectedItems.push(value2);
        }
      });
    });
    this.itemList = [];
    this.location.filter((value, index) => {
      value.regions.forEach((value2) => {
        if (value.name === this.yacht.destination) {
          this.itemList.push(value2);
        }
      });
    });
    $('.dropdown-list').attr('id', this.yacht.destination + '-list');
  }
  // tslint:disable-next-line:typedef
  loadDataSet2($event: any) {
    $('.dropdown-list').attr('id', $event.target.value + '-list');
    this.selectedItems = [];
    this.itemList = [];
    this.location.filter((value, index) => {
      value.regions.forEach((value2) => {
        if (value.name === $event.target.value) {
          this.itemList.push(value2);
        }
      });
    });
  }

  // tslint:disable-next-line:typedef
  onItemSelect(item: any) {}
  // tslint:disable-next-line:typedef
  OnItemDeSelect(item: any) {}
  // tslint:disable-next-line:typedef
  onSelectAll(items: any) {}
  // tslint:disable-next-line:typedef
  onDeSelectAll(items: any) {}

  // tslint:disable-next-line:typedef
  // getCheckboxchecked(){
  //  // $('div[id ^= checkboxes]').hide();
  //   $('div[id ^= checkboxes] input').prop('checked' , false);
  // }

  // tslint:disable-next-line:typedef
  showCalender() {
    $('#cstm-datepicker').click();
  }

  // getEnqueryData(){
  //   this.getAllEnquiry();
  //   this.setEnquiryParam();
  // }
  // tslint:disable-next-line:typedef
  getYachtsData() {
    this.isInit = true;
    this.getRegionSelection();
    this.getAllYachts();
    this.setYachtParam();
  }

  // tslint:disable-next-line:typedef
  setYachtParam() {
    this.showresults = {
      destination: this.yacht.destination,
      daterange:
        moment(this.startDate).format('Do') +
        ' - ' +
        moment(this.endDate).format('Do MMM YYYY'),
    };
    //quantaty1
    console.log('yacht', this.yacht);
    
    let data = {
      ...this.yacht,
      //
      inputBoatLength: this.inputBoatLength,
      inputBoatAge: this.inputBoatAge,
      inputBoatCabin: this.inputBoatCabin,
      inputBoatToilet: this.inputBoatToilet,
      inputBoatBudget: this.inputBoatBudget,
      mincabin: this.mincabin,
      maxcabin: this.maxcabin,
      adultguest: this.adultguest,
      childguest: this.childguest,
      infantguest: this.infantguest,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      // queryParams: this.yacht,
      queryParams: data,
    });

    let adultguestValue = localStorage.getItem("adultguest");
    let childguestVal = localStorage.getItem("childguest");
    let infantguestVal = localStorage.getItem("infantguest");


    localStorage.removeItem("adultguest");
    localStorage.removeItem("childguest");
    localStorage.removeItem("infantguest");
  }

  // tslint:disable-next-line:typedef
  getAllYachts() {
    this.apiService.getYachts(this.yacht).subscribe((res) => {
      console.log(JSON.parse(res));
      this.response = JSON.parse(res);
      this.getFilterData();
      if (this.isLoad === true) {
        this.loadMoreCustom();
        $('.loader_container').hide();
        $('.yacht_container').show();
      }
    });
    this.startDate = new Date(this.yacht.dateValue);
    this.endDate = new Date(this.yacht.dateEndValue);
  }

    // tslint:disable-next-line:typedef
    setEnquiryParam() {
      // enquiry form set param
      this.showresultsEnquiry = {
        daterange:
          moment(this.startDate).format('Do') +
          ' - ' +
          moment(this.endDate).format('Do MMM YYYY'),
      };
      console.log('enquery', this.enquery);
      
      let data = {
        ...this.enquery,
        //
      };
      this.router.navigate([], {
        relativeTo: this.route,
        // queryParams: this.yacht,
        queryParams: data,
      });
  
      // let adultguestValue = localStorage.getItem("adultguest");
      // let childguestVal = localStorage.getItem("childguest");
      // let infantguestVal = localStorage.getItem("infantguest");
  
  
      // localStorage.removeItem("adultguest");
      // localStorage.removeItem("childguest");
      // localStorage.removeItem("infantguest");
    }
      // tslint:disable-next-line:typedef
      getAllEnquiry() {
        this.apiService.getYachts(this.enquery).subscribe((res) => {
          console.log(JSON.parse(res));
          this.response = JSON.parse(res);
          // if (this.isLoad === true) {
          //   this.loadMoreCustom();
          //   $('.loader_container').hide();
          //   $('.yacht_container').show();
          // }
        });
        this.startDate = new Date(this.enquery.Start_Date);
        this.endDate = new Date(this.enquery.End_Date);
      }
  // tslint:disable-next-line:typedef
  isEmpty(obj: { hasOwnProperty: (arg0: string) => any }) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  // tslint:disable-next-line:typedef
  getRegionSelection() {
    this.yacht.regions = '';
    this.checkedIDs = [];
    this.selectedItems.forEach((value: any) => {
      this.checkedIDs.push(value.itemName);
      this.strcheckedIDs = this.checkedIDs.toString();
      this.yacht.regions = this.strcheckedIDs;
    });
  }
  // tslint:disable-next-line:typedef
  changeSelectionIsland($event: any) {
    // this.yacht.regions = '';
    // this.location.forEach((value) => {
    // this.checkedIDs = [];
    // value.isLands.forEach((value2) => {
    //   if (value2.isChecked === true && value2.country === value.name) {
    //   value2.regions.filter((value3, index) => {
    //       this.checkedIDs.push(value3.label);
    //   });
    //   this.strcheckedIDs = this.checkedIDs.toString();
    //   this.yacht.regions = this.strcheckedIDs;
    //   }
    // });
    // });
  }

  // tslint:disable-next-line:typedef
  changeBoatTypeSelection($event: any) {
    this.fetchBoatIDs($event);
    $('#yacht-1').parent().addClass('active_filter');
    $('#all_boats').prop('checked', false);
    this.loadMoreCustom();
  }

  // tslint:disable-next-line:typedef
  changeFacilityTypeSelection() {
    this.fetchFacilityIDs();
    if (this.strfacilitycheckedIDs.length) {
      $('#more-filter').parent().addClass('active_filter');
    } else {
      $('#more-filter').parent().removeClass('active_filter');
    }
    this.loadMoreCustom();
  }

  resetFilters() {
    this.strfacilitycheckedIDs = '';
    $('#more-filter').parent().removeClass('active_filter');

    this.minboatlen = this.minboatrange;
    this.maxboatlen = this.maxboatrange;
    $('#boat-length').parent().removeClass('active_filter');

    this.minboatage = this.minboatagerange;
    this.maxboatage = this.maxboatagerange;
    $('#boat-age').parent().removeClass('active_filter');

    this.mincabin = this.minboatcabinrange;
    this.maxcabin = this.maxboatcabinrange;
    $('#boat-cabin').parent().removeClass('active_filter');

    this.mintoilet = this.minboattoiletrange;
    this.maxtoilet = this.maxboattoiletrange;
    $('#boat-toilet').parent().removeClass('active_filter');

    this.minprice = this.minboatpricerange;
    this.maxprice = this.maxboatpricerange;
    $('#boat-budget').parent().removeClass('active_filter');
  }

  // tslint:disable-next-line:typedef
  fetchSelectedItems() {
    // const arrayRegionsEl  = this.yacht.regions.split(',');
    // this.selectedRegionsItemsList = this.location.forEach((value) => {
    //   value.isLands.forEach((value2) => {
    //     value2.regions.filter((value3, index) => {
    //       if (arrayRegionsEl.indexOf(value3.label) > -1){
    //         value3.isChecked = true;
    //         return true;
    //       }
    //       else{
    //         value3.isChecked = false;
    //         return false;
    //       }
    //     });
    //   });
    // });
  }

  // tslint:disable-next-line:typedef
  fetchSelectedBoat() {
    const arrayEl = this.strboatcheckedIDs.split(',');
    this.selectedItemsList = this.boatTypes.filter((value, index) => {
      if (arrayEl.indexOf(value.label) > -1) {
        value.isChecked = true;
        return true;
      } else {
        value.isChecked = false;
        return false;
      }
    });
  }

  // tslint:disable-next-line:typedef
  fetchSelectedFacility() {
    const arrayFacilityEl = this.strfacilitycheckedIDs.split(',');
    this.selectedfacilityItemsList = this.facilityTypes.filter(
      (value, index) => {
        if (arrayFacilityEl.indexOf(value.label) > -1) {
          value.isChecked = true;
          return true;
        } else {
          value.isChecked = false;
          return false;
        }
      }
    );
  }

  // tslint:disable-next-line:typedef
  fetchAllBoats() {
    // $('#yacht-1').parent().addClass('active_filter');
    $('#yacht-1').parent().removeClass('active_filter');
    this.strboatcheckedIDs = '';
    this.checkedboatIDs = [];
    this.boatTypes.forEach((value) => {
      this.checkedboatIDs.push(value.label);
    });
    this.strboatcheckedIDs = this.checkedboatIDs.toString();
    console.log('this.strboatcheckedIDs:: ', this.strboatcheckedIDs);
    this.getFilterData();
  }

  // tslint:disable-next-line:typedef
  fetchBoatIDs($event: any) {
    // console.log($event.target.value);
    this.strboatcheckedIDs = '';
    this.checkedboatIDs = [];
    this.boatTypes.forEach((value, index) => {
      if (value.isChecked && value.label === $event.target.value) {
        this.checkedboatIDs.push(value.label);
      } else {
        value.isChecked = false;
      }
    });
    this.strboatcheckedIDs = this.checkedboatIDs.toString();
    this.getFilterData();
  }

  // tslint:disable-next-line:typedef
  fetchFacilityIDs() {
    this.strfacilitycheckedIDs = '';
    this.checkedfacilityIDs = [];
    this.facilityTypes.filter((value, index) => {
      if (value.isChecked) {
        this.checkedfacilityIDs.push(value.label);
      }
    });
    this.strfacilitycheckedIDs = this.checkedfacilityIDs.toString();
    this.getFilterData();
  }

  // tslint:disable-next-line:typedef
  getminmaxvalues() {
    const data = this.response.yachts;
    // console.log(data);
    // const uniqueBoatLength = [...new Set(data.map((item: {Boat_Length: number; }) => item.Boat_Length))];
    const max = (a: any, f: any) =>
      a.reduce((m: any, x: any) => (m[f] > x[f] ? m : x));
    const min = (a: any, f: any) =>
      a.reduce((m: any, x: any) => (m[f] < x[f] ? m : x));
    this.minboatrange = min(data, 'Boat_Length').Boat_Length;
    console.log(this.minboatrange);
    this.maxboatrange = max(data, 'Boat_Length').Boat_Length;
    console.log(this.maxboatrange);
    this.minboatagerange = min(data, 'Boat_Age').Boat_Age;
    this.maxboatagerange = max(data, 'Boat_Age').Boat_Age;
    this.minboatcabinrange = Number(min(data, 'Cabins').Cabins);
    this.maxboatcabinrange = Number(max(data, 'Cabins').Cabins);
    this.minboattoiletrange = min(data, 'Toilet').Toilet;
    this.maxboattoiletrange = max(data, 'Toilet').Toilet;
    this.minboatpricerange = min(data, 'DiscountedPrice').DiscountedPrice;
    this.maxboatpricerange = max(data, 'DiscountedPrice').DiscountedPrice;
    if (this.maxboatcabinrange == this.minboatcabinrange) {
      this.minboatcabinrange = 1;
    }

    var lowest = Number.POSITIVE_INFINITY;
    var highest = Number.NEGATIVE_INFINITY;
    var tmp;
    for (var i = data.length - 1; i >= 0; i--) {
      tmp = Number(data[i].Cabins);
      if (tmp < lowest) lowest = tmp;
      if (tmp > highest) highest = tmp;
    }
    this.minboatcabinrange = lowest;
    this.maxboatcabinrange = highest;
  }
  // tslint:disable-next-line:typedef
  // getDateData($event: any) {
  //   const daterange = $event.target.value;
  //   console.log('rangedate', daterange);
  //   const datearr = daterange.split(' to ');
  //   const sdatearr = datearr[0];
  //   const edatearr = datearr[1];
  //   // console.log('sdate', sdatearr);
  //   // console.log('edate', edatearr);
  //   this.yacht.dateValue = sdatearr;
  //   this.yacht.dateEndValue = edatearr;
  //   console.log(this.yacht);
  // }

  // tslint:disable-next-line:typedef
  getBoatLength($event: any) {
    $('#boat-length').parent().addClass('active_filter');
    this.minboatlen = this.inputBoatLength[0];
    this.maxboatlen = this.inputBoatLength[1];
    if (
      this.minboatrange == this.minboatlen &&
      this.maxboatrange == this.maxboatlen
    ) {
      $('#boat-length').parent().removeClass('active_filter');
    }
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatAge() {
    $('#boat-age').parent().addClass('active_filter');
    this.minboatage = this.inputBoatAge[0];
    this.maxboatage = this.inputBoatAge[1];
    if (
      this.minboatagerange == this.minboatage &&
      this.maxboatagerange == this.maxboatage
    ) {
      $('#boat-age').parent().removeClass('active_filter');
    }
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatCabin() {
    $('#boat-cabin').parent().addClass('active_filter');
    this.mincabin = this.inputBoatCabin[0];
    this.maxcabin = this.inputBoatCabin[1];
    if (
      this.minboatcabinrange == this.mincabin &&
      this.maxboatcabinrange == this.maxcabin
    ) {
      $('#boat-cabin').parent().removeClass('active_filter');
    }
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatToilet() {
    $('#boat-toilet').parent().addClass('active_filter');
    this.mintoilet = this.inputBoatToilet[0];
    this.maxtoilet = this.inputBoatToilet[1];
    if (
      this.minboattoiletrange == this.mintoilet &&
      this.maxboattoiletrange == this.maxtoilet
    ) {
      $('#boat-toilet').parent().removeClass('active_filter');
    }
    this.getFilterData();
    this.showMore();
  }

  // tslint:disable-next-line:typedef
  getBoatBudget() {
    $('#boat-budget').parent().addClass('active_filter');
    this.minprice = this.inputBoatBudget[0];
    this.maxprice = this.inputBoatBudget[1];
    if (
      this.minboatpricerange == this.minprice &&
      this.maxboatpricerange == this.maxprice
    ) {
      $('#boat-budget').parent().removeClass('active_filter');
    }
    this.getFilterData();
    this.showMore();
  }

    // tslint:disable-next-line:typedef
    sortByPrice() {
      const sortResult = this.yachts;
      sortResult.sort(
        (
          element1: { DiscountedPrice: number },
          element2: { DiscountedPrice: number }
        ) => {
          // Ascending: first price less than the previous
          return this.sortBy === 'asc'
            ? element1.DiscountedPrice - element2.DiscountedPrice
            : element2.DiscountedPrice - element1.DiscountedPrice;
        }
      );
      this.yachts = sortResult;
      this.getFilterData();
      this.showMore();
    }
  // tslint:disable-next-line:typedef
  getFilterData() {
    this.yachts = [];
    this.isLoad = false;
    // tslint:disable-next-line:no-shadowed-variable
    let adultguestValue = localStorage.getItem("adultguest");
    let childguestVal = localStorage.getItem("childguest");
    let infantguestVal = localStorage.getItem("infantguest");


    localStorage.removeItem("adultguest");
    localStorage.removeItem("childguest");
    localStorage.removeItem("infantguest");

    console.log(Number(this.minboatage), 'this.response:: ', this.response);
    let that = this;
    const result1 = this.response.yachts.filter(
      (element: {
        type: string;
        Boat_Length: number;
        Boat_Age: number;
        Cabins: number;
        Toilet: number;
        DiscountedPrice: number;
        Facilities: Array<any>;
      }) => {
        const facilityArr: any = [];
        element.Facilities.forEach((facility) => {
          facilityArr.push(facility.name);
        });
        console.log(
          'Boat age :: ',
          element.Boat_Age,
          that.minboatage,
          Number(element.Boat_Age) >= Number(that.minboatage)
        );
        console.log(
          '>> ',
          (that.checkedboatIDs.length > 0
            ? that.checkedboatIDs.indexOf(element.type) !== -1
            : true) &&
            (that.minboatlen
              ? element.Boat_Length >= Number(that.minboatlen)
              : true) &&
            (that.maxboatlen
              ? element.Boat_Length <= Number(that.maxboatlen)
              : true) &&
            (that.minboatage
              ? Number(element.Boat_Age) >= Number(that.minboatage)
              : true) &&
            (that.maxboatage
              ? element.Boat_Age <= Number(that.maxboatage)
              : true) &&
            (that.mincabin ? element.Cabins >= Number(that.mincabin) : true) &&
            (that.maxcabin ? element.Cabins <= Number(that.maxcabin) : true) &&
            (that.mintoilet
              ? element.Toilet >= Number(that.mintoilet)
              : true) &&
            (that.maxtoilet
              ? element.Toilet <= Number(that.maxtoilet)
              : true) &&
            (that.minprice
              ? element.DiscountedPrice >= Number(that.minprice)
              : true) &&
            (that.maxprice
              ? element.DiscountedPrice <= Number(that.maxprice)
              : true) &&
            (that.checkedfacilityIDs.length > 0 && facilityArr.length > 0
              ? that.checkedfacilityIDs.every(
                  (elem) => facilityArr.indexOf(elem) > -1
                )
              : that.checkedfacilityIDs.length > 0 && facilityArr.length === 0
              ? false
              : true)
        );
        // tslint:disable-next-line:no-unused-expression
        return (
          (that.checkedboatIDs.length > 0
            ? that.checkedboatIDs.indexOf(element.type) !== -1
            : true) &&
          (that.minboatlen
            ? element.Boat_Length >= Number(that.minboatlen)
            : true) &&
          (that.maxboatlen
            ? element.Boat_Length <= Number(that.maxboatlen)
            : true) &&
          (that.minboatage
            ? Number(element.Boat_Age) >= Number(that.minboatage)
            : true) &&
          (that.maxboatage
            ? element.Boat_Age <= Number(that.maxboatage)
            : true) &&
          (that.mincabin ? element.Cabins >= Number(that.mincabin) : true) &&
          (that.maxcabin ? element.Cabins <= Number(that.maxcabin) : true) &&
          (that.mintoilet ? element.Toilet >= Number(that.mintoilet) : true) &&
          (that.maxtoilet ? element.Toilet <= Number(that.maxtoilet) : true) &&
          (that.minprice
            ? element.DiscountedPrice >= Number(that.minprice)
            : true) &&
          (that.maxprice
            ? element.DiscountedPrice <= Number(that.maxprice)
            : true) &&
          (that.checkedfacilityIDs.length > 0 && facilityArr.length > 0
            ? that.checkedfacilityIDs.every(
                (elem) => facilityArr.indexOf(elem) > -1
              )
            : that.checkedfacilityIDs.length > 0 && facilityArr.length === 0
            ? false
            : true)
        );
      }
    );

    result1.sort(
      (
        element1: { DiscountedPrice: number },
        element2: { DiscountedPrice: number }
      ) => {
        // Ascending: first price less than the previous
        return this.sortBy === 'asc'
          ? element1.DiscountedPrice - element2.DiscountedPrice
          : element2.DiscountedPrice - element1.DiscountedPrice;
      }
    );
    this.yachts = result1;
    console.log('this.yachts:: ', this.yachts);
    this.changeRef.detectChanges();
    if (this.isInit === true) {
      this.getminmaxvalues();
      this.isInit = false;
    }
    this.isLoad = true;
    console.log(this.yachts);
    console.log(this.isLoad);
    localStorage.setItem('dataYachts', JSON.stringify(this.yachts));

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        destination: this.yacht.destination,
        regions: this.yacht.regions,
        dateValue: this.yacht.dateValue,
        dateEndValue: this.yacht.dateEndValue,
        noOfPeople: this.yacht.noOfPeople,
        skipper: this.yacht.skipper,
        type: this.strboatcheckedIDs,
        minboatlen: this.minboatlen,
        maxboatlen: this.maxboatlen,
        minboatage: this.minboatage,
        maxboatage: this.maxboatage,
        mincabin: this.mincabin,
        maxcabin: this.maxcabin,
        mintoilet: this.mintoilet,
        maxtoilet: this.maxtoilet,
        minprice: this.minprice,
        maxprice: this.maxprice,
        facility: this.strfacilitycheckedIDs,
        sortBy: this.sortBy,
        //
        inputBoatLength: this.inputBoatLength,
        inputBoatAge: this.inputBoatAge,
        inputBoatCabin: this.inputBoatCabin,
        inputBoatToilet: this.inputBoatToilet,
        inputBoatBudget: this.inputBoatBudget,
        adultguest: this.adultguest,
        childguest: this.childguest,
        infantguest: this.infantguest,
        enquiryAdultguest: this.enquiryAdultguest,
        enquiryChildguest: this.enquiryChildguest,
        enquiryInfantguest: this.enquiryInfantguest
        // ENQUIRY EDITS
        
      },
      queryParamsHandling: 'merge',
    });
    setTimeout(() => {
      console.log('HELLO:: ', this.strboatcheckedIDs.indexOf(','));
      if (this.strboatcheckedIDs.indexOf(',') > -1) {
        // $('.visibleyatchs').addClass('yachts_enable_for_now');
        const sizeli = $('.visibleyatchs').length;
        const totalShown = $('.yachts_enable_for_now').length;
        const loaddivval = totalShown + 10 <= sizeli ? totalShown + 10 : sizeli;
        $('.visibleyatchs:lt(' + loaddivval + ')').addClass(
          'yachts_enable_for_now'
        );
        const remainVal = sizeli - totalShown;
        console.log(remainVal);
        if (remainVal < 10) {
          $('.load_more').hide();
        }
      }
    }, 1000);
  }

  // tslint:disable-next-line:typedef
  showMore() {
    if (this.isLoad === true) {
      this.loadMoreCustom();
    }
  }
  // tslint:disable-next-line:typedef
  loadMoreCustom() {
    $('.load_more').hide();
    setTimeout(() => {
      // $('li.dropdown').removeClass('active_filter');
      $('.visibleyatchs').removeClass('yachts_enable_for_now');
      const loaddivval = 10;
      const sizeli = $('.visibleyatchs').length;
      $('.visibleyatchs:lt(' + loaddivval + ')').addClass(
        'yachts_enable_for_now'
      );
      if (sizeli > 10) {
        $('.load_more').show();
      } else {
        $('.load_more').hide();
      }
    }, 1);
  }

  // Begin:: like unlike functionality
  // tslint:disable-next-line:typedef
  likeUnlike($event: any) {
    this.favIds = [];
    const item = $event.target.parentElement;
    item.classList.toggle('heart-checked');
    // tslint:disable-next-line:no-shadowed-variable
    const heart = $('.heart');
    // tslint:disable-next-line:no-shadowed-variable
    $('.heart').each((index: any, element: any) => {
      if (element.classList.contains('heart-checked')) {
        const id = element.attributes['data-id'].value;
        this.favIds.push(id);
      }
    });
    this.strfavIds = this.favIds.toString();
  }
  // End:: like unlike functionality

  // tslint:disable-next-line:typedef
  getCountryFlag($country: any) {
    switch ($country) {
      case 'Croatia': {
        return 'hr';
      }
      case 'Greece': {
        return 'gr';
      }
      case 'Turkey': {
        return 'tr';
      }
      case 'Italy': {
        return 'it';
      }
      case 'Spain': {
        return 'es';
      }
      default: {
        return 'hr';
      }
    }
  }

  // tslint:disable-next-line:typedef
  getSkipperText($skipper: any) {
    switch ($skipper) {
      case 'Skipper Required': {
        return 'Including skipper';
      }
      case 'Host Required': {
        return 'Including host';
      }
      case 'Skipper and Host Required': {
        return 'Including crew';
      }
      default: {
        return 'Yacht  only price';
      }
    }
  }

  // tslint:disable-next-line:typedef
  numberWithCommas($number: number) {
    const parts = $number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  // tslint:disable-next-line:typedef
  getRegionsByLocation() {
    // const country = $('#country').val();
    // this.regionData = this.location.forEach((value) => {
    //   value.isLands.filter((value2) => {
    //     if (value2.country === country){
    //       return true;
    //     }
    //     else{
    //       return false;
    //     }
    //   });
    // });
    // console.log(this.regionData);
  }
  showMenu() {
    const openMobileMenuBtn = $('#expoloreHeader');
    const headerMenu = $('.listing_form');
    const menuBtnClose = $('#expoloreHeaderRemove');
    $(openMobileMenuBtn).on('click', (e: any) => {
      e.preventDefault();
      headerMenu.addClass('active');
      openMobileMenuBtn.addClass('explore_btnOpen');
      menuBtnClose.removeClass('explore_btnClose');
    });

    $(menuBtnClose).on('click', (e: any) => {
      e.preventDefault();
      headerMenu.removeClass('active');
      openMobileMenuBtn.removeClass('explore_btnOpen');
      menuBtnClose.addClass('explore_btnClose');
    });
  }

  // Multistep Form
  get personal() {
    return this.personalDetails.controls;
  }
  get education() {
    return this.educationalDetails.controls;
  }
  get address() {
    return this.addressDetails.controls;
  }

  next() {
    if (this.step == 1) {
      console.log('this.personalDetails this.step 1',this.personalDetails)
      this.personal_step = true;
      if (this.personalDetails.invalid) {
        return;
      }
      this.sharedservice.enqueryData =  this.personalDetails.value;
        const  data2 = {
          First_Name__c : this.sharedservice.enqueryData.firstName,
          Last_Name__c :  this.sharedservice.enqueryData.lastName,
          Email__c: this.sharedservice.enqueryData.email,
          Phone__c: this.sharedservice.enqueryData.Mobile.number,
          action: 'home_page_form_mail_data',
        }
        if(data2 && data2.Email__c){
          this.apiService.getEmailApi((data2)).subscribe(res=>{         
            if(res){
              console.log('email respo==>',res)
            //  this.step++;
            }   
          })
        }
        this.step++;
      setTimeout(() => {
        const today = new Date();
        $('#cstm-datepicker-start').daterangepicker({
          opens: "left",
          Start_Date: moment(this.enquery.Start_Date).format('D MMMM YYYY'),
          End_Date: moment(this.enquery.End_Date).format('D MMMM YYYY'),
          minDate: today,
          maxSpan: {
            days: 28,
          },
          locale: {
            monthNames: moment.months(),
            format: 'D MMMM YYYY',
            separator: ' - ',
          },
          isInvalidDate: (date: any) => {
            if (date.day() === 6) {
              return false;
            } else {
              return true;
            }
          },
          
        })
      }, 1000);

      // this.sharedservice.emailData  = Object.assign(this.personalDetails.value,this.sharedservice.emailData);      



    }

    // console.log($('#cstm-datepicker-start').datepicker())
    
  }

  next2(){
    if (this.step == 2) {
      this.addressDetails.patchValue({
        No_of_people_sailing:  this.enquiryAdultguest + this.enquiryChildguest + this.enquiryInfantguest,
        // dateOfHolidays: this.Start_Date + this.End_Date,
      })
      console.log(' this.addressDetails', this.addressDetails);
      this.address_step = true;
      if (this.addressDetails.invalid) {
        return;
      }

      this.sharedservice.enqueryData = Object.assign(this.addressDetails.value,this.sharedservice.enqueryData);      
      this.step++;
      this.getAllEnquiry();
    }
  }

  onChangeDate(ev:any){
    console.log('i am here',ev)
  }
  previous() {
    this.step--;
    if (this.step == 1) {
      this.personal_step = false;
    }
    if (this.step == 2) {
      this.education_step = false;
    }
  }

  submit2() {
    if (this.step == 3) {
      this.education_step = true;
      this.sharedservice.enqueryData = Object.assign(this.educationalDetails.value,this.sharedservice.enqueryData);      
      const  data = {
        Boat_Type_Preference : this.sharedservice.enqueryData.Boat_Type_Preference,
        Preferred_destination :  this.sharedservice.enqueryData.destination,
        Email: this.sharedservice.enqueryData.email,
        No_Of_Cabins: parseInt(this.sharedservice.enqueryData.No_Of_Cabins),
        No_of_people_sailing:  String(this.sharedservice.enqueryData.No_of_people_sailing),
        // Comment: this.sharedservice.enqueryData.Comment,
        First_Name:  this.sharedservice.enqueryData.firstName,
        Select_Budget:  this.sharedservice.enqueryData.budget,
        Last_Name: this.sharedservice.enqueryData.lastName,
        Start_Date:'2022-02-26',
        End_Date: '2022-03-26',
        Crew_Required:  this.sharedservice.enqueryData.Crew_Required,
        No_Of_Toilets:  this.sharedservice.enqueryData.No_Of_Toilets,
        Mobile: this.sharedservice.enqueryData.Mobile.number,
      }
      this.apiService.getEnquiry((data)).subscribe(res=>{
          console.log('res==> i am here',data)
          // console.log(res.status);
          // if(res.status == 200){
          //           $("#loader_mainID").hide();
          //   $( "#model_close" ).trigger( "click" );
          // }
      })
      $( "#model_close" ).trigger( "click" );
      // $("#loader_mainID").show();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  // create quanataty
  // quantaty
  plus1() {
    if (this.adultguest != 10) {
      this.adultguest++;
      this.adultguest = this.adultguest;

     // console.log("this.adultguest", this.adultguest);
    }
  }
  minus1() {
    if (this.adultguest != 0) {
      this.adultguest--;
      this.adultguest = this.adultguest;
    }
  }
  plus2() {
    if (this.childguest != 10) {
      this.childguest++;
      this.childguest = this.childguest;
    }
  }
  minus2() {
    if (this.childguest != 0) {
      this.childguest--;
      this.childguest = this.childguest;
    }
  }
  plus3() {
    if (this.infantguest != 10) {
      this.infantguest++;
      this.infantguest = this.infantguest;
    }
  }
  minus3() {
    if (this.infantguest != 0) {
      this.infantguest--;
      this.infantguest = this.infantguest;
    }
  }
  guestfunc(){
    const openGuestDrop = $('.guest-s');
    const totalGuest = $('.total-guest');
    const guestSlide = $('.guest-slide');
    openGuestDrop.on('click', function(){
      totalGuest.addClass('show');
      guestSlide.addClass('show');
    })
    openGuestDrop.on('mouseleave', function(){
      totalGuest.removeClass('show');
      guestSlide.removeClass('show');
    })
  }
  // Enquiry form Quantity
  enquiryplus1() {
    if (this.enquiryAdultguest != 10) {
      this.enquiryAdultguest++;
      this.enquiryAdultguest = this.enquiryAdultguest;
      console.log(this.enquiryAdultguest);
    }
  }
  enquiryminus1() {
    if (this.enquiryAdultguest != 0) {
      this.enquiryAdultguest--;
      this.enquiryAdultguest = this.enquiryAdultguest;
    }
  }
  enquiryplus2() {
    if (this.enquiryChildguest != 10) {
      this.enquiryChildguest++;
      this.enquiryChildguest = this.enquiryChildguest;
    }
  }
  enquiryminus2() {
    if (this.enquiryChildguest != 0) {
      this.enquiryChildguest--;
      this.enquiryChildguest = this.enquiryChildguest;
    }
  }
  enquiryplus3() {
    if (this.enquiryInfantguest != 10) {
      this.enquiryInfantguest++;
      this.enquiryInfantguest = this.enquiryInfantguest;
    }
  }
  enquiryminus3() {
    if (this.enquiryInfantguest != 0) {
      this.enquiryInfantguest--;
      this.enquiryInfantguest = this.enquiryInfantguest;
    }
  }
  enquirygGuestfunc(){
    const enquiryOpenGuestDrop = $('.enquiryform-field');
    const enquiryTotalGuest = $(' .enquiry-total-guest');
    const enquiryGuestSlide = $('.enquiry-guest-slide');
    enquiryOpenGuestDrop.on('click', function(){
      enquiryTotalGuest.addClass('show');
      enquiryGuestSlide.addClass('show');
    })
    enquiryOpenGuestDrop.on('mouseleave', function(){
      enquiryTotalGuest.removeClass('show');
      enquiryGuestSlide.removeClass('show');
    })
  }

}
