<section class="fvrt-gallery-sec">
		<div class="container">
			<div class="fvrt_top_content">
                <div class="row p30">
                    <div class="top_content_left">
                        <h1>Your Favourites </h1>
                    </div>
                    <div class="fvrt-btn-form">
                        <!-- <div class="make-send">
                            <button class="fav-btn" onclick="">MAKE AN ENQUIRY</button>
                            <button class="fav-btn" style="margin: 0 10px;">SEND THIS LIST TO A FRIEND</button>
                        </div> -->
                        <div class="input-group">
                            <div class="fvrt_sort">
                                <p>Sort By</p>
                                <div class="sorting-filter">
                                    <select id="img_filter" class="form-control pricefilter selectpicker" (change)="sortByPrice($event)">
                                    <option value="">Sort</option>
                                    <option value="asc">Price: Low to High</option>
                                    <option value="desc">Price: High to Low</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="loader_container_fav">
                    <div id="load" class="center"></div>
            </div>
			<div class="row" id="yachts" *ngIf="yachts.length">
                <div class="col-lg-3 col-md-4 col-sm-12"  *ngFor="let yatchItem of yachts">
                  <div class="img-gallery_card favourite-card">
                    <a href="javascript:void(0);">
                      <figure>
                        <a href="javascript:void(0);">
                        <img src="{{(yatchItem['ProfileURL'])? yatchItem['ProfileURL'] : 'assets/images/yatch-img.png'}}" class="img-fluid" class="yacht-card_img">
                        <div class="fvrt-icon"><span (click)="removeFavYacht($event)" attr.data-id="{{yatchItem['id']}}"><i class="fa fa-times"></i></span></div>
                        </a> 
                        <div class="img-content" *ngIf="yatchItem['Annotation']">
                          <p>{{yatchItem['Annotation']}}</p>
                        </div>
                    </figure>
                    </a>
                    <figcaption>
                      <div class="figcaption_title">
                        <h4><a target="_blank" href="javascript:void(0);">{{yatchItem['Model']}}</a></h4>
                        <p><span title="{{yatchItem['Location_country']}}" class="flag-icon flag-icon-{{getCountryFlag(yatchItem['Location_country'])}}"></span>{{yatchItem['BaseName']}}, {{yatchItem['searchfieldfromwebsite']}}</p>
                        <p class="loc_region_name">{{yatchItem['Location_region_name']}}</p>
                        
                      </div>
                      <hr>
                      <div class="figcaption_content">
                        <ul><div><p> Type</p><span>{{yatchItem['type']}}</span> </div> </ul>
                        <ul><div><p> Year</p><span>{{yatchItem['Boat_Age']}}</span> </div> </ul>
                        <ul><div><p> Length</p><span>{{yatchItem['Boat_Length']}}m</span> </div> </ul>
                        <ul><div><p> Cabins</p><span>{{yatchItem['Cabins']}}</span> </div> </ul>
                        <ul><div><p> Toilets</p><span>{{yatchItem['Toilet']}} </span> </div> </ul>
                      </div>
                      <hr>
                      <div class="figcaption_features">
                        <h4>Key Features</h4>
                        <div class="inventory_sec">
                            <ul>
                                <li>
                                    <span class="key_cntnt">Air Condition</span>
                                    <span class="tick" *ngIf="myarrayContainsEating(yatchItem['Facilities'], 'Air Condition')"><i class="fa fa-check"></i></span>
                                    <span class="cross" *ngIf="!myarrayContainsEating(yatchItem['Facilities'], 'Air Condition')"><i class="fa fa-times-circle"></i></span>
                                </li>
                                <li>
                                    <span class="key_cntnt">Autopilot</span>
                                     <span class="tick" *ngIf="myarrayContainsEating(yatchItem['Facilities'], 'Autopilot')"><i class="fa fa-check"></i></span>
                                    <span class="cross" *ngIf="!myarrayContainsEating(yatchItem['Facilities'], 'Autopilot')"><i class="fa fa-times-circle"></i></span>
                                </li>
                                <li>
                                    <span class="key_cntnt">Generator</span>
                                     <span class="tick" *ngIf="myarrayContainsEating(yatchItem['Facilities'], 'Generator')"><i class="fa fa-check"></i></span>
                                    <span class="cross" *ngIf="!myarrayContainsEating(yatchItem['Facilities'], 'Generator')"><i class="fa fa-times-circle"></i></span>
                                </li>
                                <li>
                                    <span class="key_cntnt">Bow thruster</span>
                                     <span class="tick" *ngIf="myarrayContainsEating(yatchItem['Facilities'], 'Bow thruster')"><i class="fa fa-check"></i></span>
                                    <span class="cross" *ngIf="!myarrayContainsEating(yatchItem['Facilities'], 'Bow thruster')"><i class="fa fa-times-circle"></i></span>
                                </li>
                            </ul>
                        </div>
                      </div>
                      <hr>
                      <div class="figcaption_bottom" >
                          <div class="figcaption_bottom_content">
                            <!-- <ul>
                              <li><div>You are saving<span><del>£{{numberWithCommas(yatchItem['Price'])}}</del> </span> </div></li>
                              <li><div>£{{numberWithCommas(yatchItem['Savings'])}}<span>  £{{numberWithCommas(yatchItem['DiscountedPrice'])}} </span> </div></li>
                              <li><div>{{yatchItem['DiscountPercent']}} % Off<span>Yacht  only price</span> </div></li>
                            </ul> -->
                            <ul>
                              <li><div><del>£{{numberWithCommas(yatchItem['Price'])}}</del><span>  £{{numberWithCommas(yatchItem['DiscountedPrice'])}} </span> </div></li>
                              <li><div>{{yatchItem['DiscountPercent']}} % Off<span>{{getSkipperText(yacht.skipper)}}</span> </div></li>
                            </ul>
                          </div>
                          <!-- <div class="figcaption_bottom_content">
                              <ul>
                                <li>
                                  <div>
                                    <span>Price and discount are empty</span>
                                  </div>
                                </li>
                              </ul>
                            </div> -->
                        </div>
                    </figcaption>
                  </div>
                </div>
              </div>
		</div>
	</section>
