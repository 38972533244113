<!-- HEADER CODE -->
<div class="header-holder">
  <div class="container">
    <header class="main-header">
      <a href="{{ domainName }}" class="logo" id="logo">
        <img
          src="assets/images/sea-master-main-logo.svg"
          class="img-fluid"
          width="152px"
        />
      </a>

      <!-- <nav class="header-nav">
        <div class="header-nav__inner">
          <ul class="header-menu-list">
            <li class="header-menu-item">
              <a href="{{ domainName }}">HOME</a>
            </li>
            <li class="header-menu-item">
              <a rel="nofollow" href="{{ domainName }}/my-account"
                >LOGIN/REGISTER</a
              >
            </li>
          </ul>
        </div>
      </nav> -->
      <!-- form-start  -->
      <p class="cmn_btn" id="expoloreHeader">
        Explore Now <i class="fa fa-search"></i>
      </p>
      <div class="listing_form">
        <div class="main-form">
          <ul>
            <li>
              <label>Country</label>
              <select
                id="country"
                name="country"
                class="form-control selectpicker countrypicker"
                [(ngModel)]="yacht.destination"
                data-default="Croatia"
                data-flag="true"
                (change)="loadDataSet2($event)"
              >
                <option
                  *ngFor="let loc of location"
                  id="{{ loc.cuid }}"
                  value="{{ loc.name }}"
                  attr.data-content="<span class=&quot;flag-icon flag-icon-{{
                    loc.code
                  }}&quot;></span> {{ loc.name }}"
                >
                  {{ loc.name }}
                </option>
              </select>
            </li>
            <li class="regionDropdown">
              <label>Regions</label>
              <angular2-multiselect
                [data]="itemList"
                [(ngModel)]="selectedItems"
                [settings]="settings"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="OnItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
              >
              </angular2-multiselect>
              <!-- <button type="button" class="region-s" id="region">Select Region<i class="fa fa-caret-down" aria-hidden="true"></i></button>
                
                    <div *ngFor="let loc of location">
                      <div id="{{loc.id}}" class="region-select" style="z-index: 5; position: absolute; background-color: #fff; border: 1px solid #dadada; box-shadow: 1px;">
                      <div *ngFor="let isL of loc['isLands']">
                        <input type="checkbox" class="islandchkbox" id="{{isL.id}}" value="{{isL.label}}" [(ngModel)]="isL.isChecked" (change)="changeSelectionIsland($event)">
                        <label for="{{isL.id}}" class="island-label"> {{isL.label}}</label><br>
                        <div class="region-names">
                            <div class="region-name" *ngFor="let reg of isL['regions']">
                              <input class="{{isL.id}}" type="checkbox" id="{{reg.id}}" name="region[]" value="{{reg.label}}" [(ngModel)]="reg.isChecked" (change)="changeSelection($event)">
                              <label for="{{reg.id}}" class="region-label">{{reg.label}}</label>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div> -->
            </li>
            <li>
              <div class="input-field">
                <label for="cstm-datepicker">Duration</label>
                <input
                  type="text"
                  id="cstm-datepicker"
                  [(ngModel)]="minMaxDate"
                />
                <!-- <i
                class="fa fa-calendar"
                aria-hidden="true"
                (click)="showCalender()"
              ></i> -->
              </div>
            </li>
            <!-- new quantaty dropdown design -->
            <li class="guest-s search-box-li-class" (click)="(guestfunc)">
              <label for="people">No. of people</label>
              <span class="total-guest" id="get_gst_cst"
                >{{ adultguest + childguest + infantguest }} People
              </span>
              <div class="guest-slide">
                <div class="guest-inner">
                  <span>Adults (18 years +)</span>
                  <div class="guest-inp">
                    <button type="button" (click)="minus1()">
                      <i class="fa fa-minus"></i>
                    </button>
                    <input
                      type="text"
                      id="adultguest"
                      name="adultguest"
                      [value]="adultguest"
                    />
                    <button type="button" (click)="plus1()">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="guest-inner">
                  <span>Children (2 - 17 years)</span>
                  <div class="guest-inp" id="">
                    <button type="button" (click)="minus2()">
                      <i class="fa fa-minus"></i>
                    </button>
                    <input
                      type="text"
                      id="childguest"
                      name="childguest"
                      [value]="childguest"
                    />
                    <button type="button" (click)="plus2()">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="guest-inner">
                  <span>Infants (Under 2)</span>
                  <div class="guest-inp">
                    <button type="button" (click)="minus3()">
                      <i class="fa fa-minus"></i>
                    </button>
                    <input
                      type="text"
                      id="infantguest"
                      name="infantguest"
                      [value]="infantguest"
                    />
                    <button type="button" (click)="plus3()">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <!-- Old Quantity design Start -->
            <!-- <li>
            <label for="people">No. of people</label>
            <select
              id="people"
              name="people"
              class="form-control selectpicker"
              [(ngModel)]="yacht.noOfPeople"
            >
              <option selected="" value="2">2 People</option>
              <option value="3">3 People</option>
              <option value="4">4 People</option>
              <option value="5">5 People</option>
              <option value="6">6 People</option>
              <option value="7">7 People</option>
              <option value="8">8 People</option>
              <option value="9">9 People</option>
              <option value="10">10 People</option>
            </select>
          </li> -->
            <li class="skipper">
              <label for="skipper">Crew required</label>
              <select
                id="skipper"
                name="skipper"
                class="form-control selectpicker"
                [(ngModel)]="yacht.skipper"
              >
                <option value="Boat only">Boat only</option>
                <option value="Skipper Required">Skipper Required</option>
                <option value="Host Required">Host Required</option>
                <option value="Skipper and Host Required">
                  Skipper and Host Required
                </option>
              </select>
            </li>
            <li>
              <div class="submit_btn" (click)="getYachtsData()">
                <input
                  type="button"
                  class="submitbtnyatch"
                  id="submit"
                  value="Explore"
                />
                <i class="fa fa-search"></i>
              </div>
            </li>
          </ul>
        </div>
        <p class="cmn_btn explore_btnClose" id="expoloreHeaderRemove">
          <i class="fa fa-remove"></i>
        </p>
      </div>
      <!-- form-end  -->

      <!-- Enquiry Form -->
      <button
        type="button"
        class="cmn_btn"
        data-toggle="modal"
        data-target="#enquiryform"
      >
        <span>Make an enquiry</span>
        <img
          src="https://dev.seamaster.co.uk/wp-content/uploads/2022/02/enquiry.png"
          alt=""
        />
      </button>
      <div id="loader_mainID" class="loader_main" style="display: none;">
        <div class="loader-overlay"></div>
        <div class="loader_wrapper">
          <div class="loader"></div>
        </div>
      </div>
      <!-- Enquiry Modal -->
      <div
        class="modal fade"
        id="enquiryform"
        tabindex="-1"
        role="dialog"
        aria-labelledby="enquiryformLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                id="model_close"
                aria-label="Close"
              >
                <img
                  src="https://dev.seamaster.co.uk/wp-content/uploads/2022/01/close-blue.png"
                  alt=""
                />
              </button>
            </div>
            <div class="modal-body">
              <div class="multistep form">
              <!-- Multistep Form Start Here -->
              <div class="form-heading">
                <h3>Enquire now</h3>
                <p>
                  Tell us what you are looking for and we will provide a tailor
                  made solution
                </p>
              </div>
              <div class="row">
                <div class="col-md-12 col-md-offset-3">
                  <form
                    [formGroup]="personalDetails"
                    id="msform"
                  >
                    <!-- progressbar -->
                    <ul id="progressbar">
                      <li [ngClass]="step >= 1 ? 'active ' : 'inactive'">
                        <span class="progress-count">1</span>
                        <span class="progress-tit">You</span>
                      </li>
                      <li [ngClass]="step >= 2 ? 'active' : 'inactive'">
                        <span class="progress-count">2</span>
                        <span class="progress-tit">Your holiday</span>
                      </li>
                      <li [ngClass]="step == 3 ? 'active' : 'inactive'">
                        <span class="progress-count">3</span>
                        <span class="progress-tit">Your boat</span>
                      </li>
                    </ul>
                    <!-- fieldsets -->
                    <!-- Your Area -->
                    <fieldset *ngIf="step == 1">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>First name</label>
                            <input
                              type="text"
                              formControlName="firstName"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid':
                                  personal_step && personal.firstName.errors
                              }"
                            />
                            <div
                              *ngIf="
                                personal_step &&
                                personalDetails.controls.firstName.errors
                              "
                            >
                              <div *ngIf="personal.firstName.errors?.required" class="errormessages">
                                First name is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              formControlName="lastName"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid':
                                  personal_step && personal.lastName.errors
                              }"
                            />
                            <div
                              *ngIf="
                                personal_step &&
                                personalDetails.controls.lastName.errors
                              "
                            >
                              <div *ngIf="personal.lastName.errors?.required" class="errormessages">
                                Last name is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Phone number </label>
                              <div class="wrapper">
                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                  [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [selectFirstCountry]="true"
                                  [selectedCountryISO]="CountryISO.UnitedKingdom"
                                  [phoneValidation]="true" [numberFormat]="PhoneNumberFormat.National" name="Mobile" formControlName="Mobile" >
                                </ngx-intl-tel-input>
                                <div *ngIf=" personal_step && personalDetails.controls.Mobile.errors " >
                                  <div *ngIf="personal.Mobile.errors?.required" class="errormessages">
                                    Phone is required
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              formControlName="email"
                              name="email"
                              class="form-control"
                            />
                            <div
                              *ngIf="
                                personal_step &&
                                personalDetails.controls.email.errors
                              "
                            >
                              <div *ngIf="personal.email.errors?.required" class="errormessages">
                                Email is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        (click)="next()"
                        type="button"
                        name="next"
                        class="next action-button"
                        value="Next"
                      />
                    </fieldset>
                  </form>
                  <!-- Your Holiday Area -->
                  <form [formGroup]="addressDetails" id="msform">
                    <fieldset *ngIf="step == 2">
                      <div class="form-group">
                        <label for="cstm-datepicker-start">Holiday dates</label>
                        <input
                          type="text"
                          class=" cstm-datepicker-start-class"
                          id="cstm-datepicker-start"
                          formControlName="dateOfHolidays"
                        />
                      </div>
                      <div class="form-group">
                        <div
                          class="enquiryform-field"
                          (click)="enquirygGuestfunc()"
                        >
                          <label for="people">No. of people</label>
                          <!-- <span class="form-control total-guest enquiry-total-guest" >{{enquiryAdultguest + enquiryChildguest + enquiryInfantguest}}</span> -->
                          <input
                            type="text"
                            class="total-guest enquiry-total-guest"
                            [value]="
                              enquiryAdultguest +
                              enquiryChildguest +
                              enquiryInfantguest
                            "
                            formControlName="No_of_people_sailing"
                            name="No_of_people_sailing"
                          />
                          <div class="guest-slide enquiry-guest-slide">
                            <div class="guest-inner">
                              <span>Adults (18 years +)</span>
                              <div class="guest-inp">
                                <button type="button" (click)="enquiryminus1()">
                                  <i class="fa fa-minus"></i>
                                </button>
                                <input
                                  type="text"
                                  id="enquiryAdultguest"
                                  name="enquiryAdultguest"
                                  [value]="enquiryAdultguest"
                                />
                                <button type="button" (click)="enquiryplus1()">
                                  <i class="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div class="guest-inner">
                              <span>Children (2 - 17 years)</span>
                              <div class="guest-inp" id="">
                                <button type="button" (click)="enquiryminus2()">
                                  <i class="fa fa-minus"></i>
                                </button>
                                <input
                                  type="text"
                                  id="enquiryChildguest"
                                  name="enquiryChildguest"
                                  [value]="enquiryChildguest"
                                />
                                <button type="button" (click)="enquiryplus2()">
                                  <i class="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div class="guest-inner">
                              <span>Infants (Under 2)</span>
                              <div class="guest-inp">
                                <button type="button" (click)="enquiryminus3()">
                                  <i class="fa fa-minus"></i>
                                </button>
                                <input
                                  type="text"
                                  id="enquiryInfantguest"
                                  name="enquiryInfantguest"
                                  [value]="enquiryInfantguest"
                                />
                                <button type="button" (click)="enquiryplus3()">
                                  <i class="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Preferred destination</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter your destination"
                          formControlName="destination"
                        />
                        <!-- <div
                          *ngIf="
                            address_step &&
                            addressDetails.controls.destination.errors
                          "
                        >
                          <div *ngIf="address.destination.errors?.required">
                            Destination is required
                          </div>
                        </div> -->
                      </div>
                      <div class="form-group">
                        <label>Select budget</label>
                        <select
                          id="inputState"
                          class="form-control"
                          formControlName="budget"
                        >
                          <option disabled selected hidden value="1500">
                            £0 - £1500 per week
                          </option>
                          <option value="5000">£1500 - £5000 per week</option>
                          <option value="10000">
                            £5000 - £10,000 per week
                          </option>
                          <option value="15000">
                            £10,000 - £15,000 per week
                          </option>
                          <option value="30000">
                            £15,000 - £30,000 per week
                          </option>
                          <option value="30000">£30,000 per week +</option>
                        </select>
                      </div>
                      <!-- <input (click)="previous()" type="button" name="previous" class="previous action-button-previous" value="Previous"/> -->
                      <input
                        (click)="next2()"
                        type="button"
                        name="next"
                        class="next action-button"
                        value="Next"
                      />
                    </fieldset>
                  </form>


                  <!-- Your Boat Area -->
                  <form [formGroup]="educationalDetails" id="msform">
                    <fieldset *ngIf="step == 3">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Preferred number of cabins </label>
                            <select
                              id="inputState"
                              name="noCabins"
                              formControlName="No_Of_Cabins"
                              class="form-control"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6+">6+</option>
                            </select>
                            <!-- <div
                              *ngIf="
                                education_step &&
                                educationalDetails.controls.noCabins.errors
                              "
                            >
                              <div *ngIf="education.noCabins.errors?.required">
                                Number of cabins is required
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Preferred number of toilets</label>
                            <select
                              id="inputState"
                              name="notoilets"
                              class="form-control"
                              formControlName="No_Of_Toilets"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6+">6+</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Preferred boat type</label>
                            <select
                              id="inputState"
                              name="boatType"
                              class="form-control"
                              formControlName="Boat_Type_Preference"
                            >
                              <option value="Monohull">Monohull</option>
                              <option value="Catamaran">Catamaran</option>
                              <option value="Power Boat">Power Boat</option>
                            </select>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label>Crew required?</label>
                            <select
                              id="inputState"
                              name="crew"
                              class="form-control"
                              formControlName="Crew_Required"
                            >
                              <option value="Boat Only">Boat Only</option>
                              <option value="Skipper Required">
                                Skipper Required
                              </option>
                              <option value="Host Required">
                                Host Required
                              </option>
                              <option value="Skipper and Host Required">
                                Skipper and Host Required
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Any other comments??</label>
                        <input
                          name="comments"
                          class="form-control"
                          formControlName="Comment"
                        />
                      </div>
                      <input
                        (click)="submit2()"
                        type="submit"
                        name="submit"
                        class="submit action-button"
                        data-dismiss="modal"
                        value="Submit"
                      />
                    </fieldset>
                  </form>

                </div>
              </div>
              <!-- Multistep Form Start End -->
              </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div> -->
          </div>
        </div>
      </div>

      <!-- <ul class="contact-list">
        <li>
          <a href="javascript:void(0);" class="contact_number">
            <span class="cnt-icon">
              <i class="fas fa-phone"></i>
            </span>
            <h5>
              <small>Have you any Query?</small>
              020 8004 3003
            </h5>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="device-menu" id="hamburger">
            <div class="device-menu__inner">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </li>
      </ul> -->
    </header>
  </div>
</div>
<!-- HEADER CODE END -->

<section class="listing-page_div" id="listing-page_div">
  <div class="container">
    <!-- filters-form start  -->

    <div class="listing_filter_form">
      <ul>
        <li class="dropdown">
          <a
            href="javascript:void(0);"
            id="yacht-1"
            class="dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src="assets/images/boat-type.png"
              alt="boat-type"
              class="img-fluid blue-img"
            />
            <img
              src="assets/images/boat-type-white.png"
              alt="boat-type"
              class="img-fluid white-img"
            />
            Boat Type</a
          >
          <div class="dropdown-menu cstm-dropdown-menu" id="yacht-2">
            <div class="region-name">
              <input
                type="checkbox"
                id="all_boats"
                value=""
                name="all_boat"
                class="all_boats_type"
                (change)="fetchAllBoats()"
              />
              <label for="all_boats">All Boats</label>
            </div>
            <div class="region-name" *ngFor="let boatType of boatTypes">
              <input
                type="checkbox"
                id="{{ boatType.id }}"
                value="{{ boatType.label }}"
                name="boat_type[]"
                [(ngModel)]="boatType.isChecked"
                (change)="changeBoatTypeSelection($event)"
                class="boat_types"
              />
              <label for="{{ boatType.id }}">{{ boatType.label }}</label>
            </div>
          </div>
        </li>
        <li class="dropdown">
          <a
            href="javascript:void(0);"
            class="dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src="assets/images/boat-lenth.png"
              alt="boat-lenth"
              class="img-fluid blue-img"
            />
            <img
              src="assets/images/boat-lenth-white.png"
              alt="boat-lenth"
              class="img-fluid white-img"
            />
            Boat Length</a
          >
          <div class="dropdown-menu cstm-dropdown-menu" id="boat-length">
            <div class="range-slider">
              <span id="boatlengthrangevalues" class="rangeValues">
                {{ inputBoatLength[0] }} - {{ inputBoatLength[1] }}</span
              ><span>(m)</span>
              <nouislider
                [min]="minboatrange"
                [max]="maxboatrange"
                [step]="0.1"
                [(ngModel)]="inputBoatLength"
                (change)="getBoatLength($event)"
              ></nouislider>
            </div>
            <!-- <div class="range-slider">
                    <span id="boatlengthrangevalues" class="rangeValues">4 - 33</span><span>(m)</span>
                    <input value="4" name="minboatlen" [(ngModel)]="minboatlen" id="minboatlen" min="4" max="33" step="0.1"  type="range">
                    <input value="33" name="maxboatlen" [(ngModel)]="maxboatlen" min="4" max="33" step="0.1" type="range">
                  </div> -->
          </div>
        </li>
        <li class="dropdown">
          <a
            href="javascript:void(0);"
            class="dropdown-toggle"
            data-toggle="dropdown"
            ><img
              src="assets/images/boat-age.png"
              alt="toilet"
              class="img-fluid blue-img"
            />
            <img
              src="assets/images/boat-age-white.png"
              alt="toilet"
              class="img-fluid white-img"
            />
            Boat Age</a
          >
          <div class="dropdown-menu cstm-dropdown-menu" id="boat-age">
            <div class="range-slider">
              <span class="rangeValues"
                >{{ inputBoatAge[0] }} - {{ inputBoatAge[1] }}</span
              >
              <nouislider
                [min]="minboatagerange"
                [max]="maxboatagerange"
                [step]="1"
                [(ngModel)]="inputBoatAge"
                (change)="getBoatAge()"
              ></nouislider>
            </div>
            <!-- <div class="range-slider">
                      <span class="rangeValues">1942 - 2021</span>
                      <input value="1942" name="minboatage" [(ngModel)]="minboatage" min="1942" max="2021" step="1" type="range">
                      <input value="2021" name="maxboatage" [(ngModel)]="maxboatage" min="1942" max="2021" step="1" type="range">
                    </div> -->
          </div>
        </li>
        <li class="dropdown">
          <a
            href="javascript:void(0);"
            class="dropdown-toggle"
            data-toggle="dropdown"
            ><img
              src="assets/images/cabins.png"
              alt="cabins"
              class="img-fluid blue-img"
            />
            <img
              src="assets/images/cabins-white.png"
              alt="cabins"
              class="img-fluid white-img"
            />
            Cabins</a
          >
          <div class="dropdown-menu cstm-dropdown-menu" id="boat-cabin">
            <div class="range-slider">
              <span class="rangeValues"
                >{{ inputBoatCabin[0] }} - {{ inputBoatCabin[1] }}</span
              >
              <nouislider
                [min]="minboatcabinrange"
                [max]="maxboatcabinrange"
                [step]="1"
                [(ngModel)]="inputBoatCabin"
                (change)="getBoatCabin()"
              ></nouislider>
            </div>
            <!-- <div class="range-slider">
                    <span class="rangeValues">1 - 12</span>
                    <input value="1" name="mincabin" [(ngModel)]="mincabin" min="1" max="12" step="1" type="range">
                    <input value="12" name="maxcabin" [(ngModel)]="maxcabin" min="1" max="12" step="1" type="range">
                  </div> -->
          </div>
        </li>
        <li class="dropdown">
          <a
            href="javascript:void(0);"
            class="dropdown-toggle"
            data-toggle="dropdown"
            ><img
              src="assets/images/toilet.png"
              alt="toilet"
              class="img-fluid blue-img"
            />
            <img
              src="assets/images/toilet-white.png"
              alt="toilet"
              class="img-fluid white-img"
            />
            Toilet</a
          >
          <div class="dropdown-menu cstm-dropdown-menu" id="boat-toilet">
            <div class="range-slider">
              <span class="rangeValues"
                >{{ inputBoatToilet[0] }} - {{ inputBoatToilet[1] }}</span
              >
              <nouislider
                [min]="minboattoiletrange"
                [max]="maxboattoiletrange"
                [step]="1"
                [(ngModel)]="inputBoatToilet"
                (change)="getBoatToilet()"
              ></nouislider>
            </div>
            <!--<div class="range-slider">
                        <span class="rangeValues">1 - 5</span>
                        <input value="1" name="mintoilet" [(ngModel)]="mintoilet" min="1" max="5" step="1" type="range">
                        <input value="5" name="maxtoilet" [(ngModel)]="maxtoilet" min="1" max="5" step="1" type="range">
                      </div> -->
          </div>
        </li>
        <li class="dropdown">
          <a
            href="javascript:void(0);"
            class="dropdown-toggle"
            data-toggle="dropdown"
            ><img
              src="assets/images/budget.png"
              alt="budget"
              class="img-fluid blue-img"
            />
            <img
              src="assets/images/budget-white.png"
              alt="budget"
              class="img-fluid white-img"
            />
            Budget</a
          >
          <div class="dropdown-menu cstm-dropdown-menu" id="boat-budget">
            <div class="range-slider">
              <span class="rangeValues"
                >£{{ numberWithCommas(inputBoatBudget[0]) }} - £{{
                  numberWithCommas(inputBoatBudget[1])
                }}
              </span>
              <nouislider
                [min]="minboatpricerange"
                [max]="maxboatpricerange"
                [step]="1"
                [(ngModel)]="inputBoatBudget"
                (change)="getBoatBudget()"
              ></nouislider>
            </div>
            <!-- <div class="range-slider">
                      <span class="rangeValues">100 - 2000</span><span>(£)</span>                 
                      <input value="100" name="minprice" [(ngModel)]="minprice" min="100" max="20000" step="1" type="range">
                      <input value="20000" name="maxprice" [(ngModel)]="maxprice" min="100" max="20000" step="1" type="range">
                  </div> -->
          </div>
        </li>
        <li class="dropdown">
          <a
            href="javascript:void(0);"
            class="dropdown-toggle"
            data-toggle="dropdown"
            ><img
              src="assets/images/more-filter.png"
              alt="more-filter"
              class="img-fluid blue-img"
            />
            <img
              src="assets/images/more-filter-white.png"
              alt="more-filter"
              class="img-fluid white-img"
            />
            More Filters</a
          >
          <div class="dropdown-menu cstm-dropdown-menu" id="more-filter">
            <div class="region-name" *ngFor="let facilityType of facilityTypes">
              <input
                type="checkbox"
                id="{{ facilityType.id }}"
                value="{{ facilityType.label }}"
                name="facility_type[]"
                [(ngModel)]="facilityType.isChecked"
                (change)="changeFacilityTypeSelection()"
                class="facility_types"
              />
              <label for="{{ facilityType.id }}">{{
                facilityType.label
              }}</label>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="cart once_fixed">
      <a
        target="_blank"
        routerLink="/favourites"
        [queryParams]="{
          ids: strfavIds,
          destination: yacht.destination,
          dateValue: yacht.dateValue,
          dateEndValue: yacht.dateEndValue,
          noOfPeople: yacht.noOfPeople,
          skipper: yacht.skipper
        }"
        routerLinkActive="active"
      >
        <i class="fas fa-heart"></i>
        <p>
          <span class="count" id="#edit-count-checked-checkboxes"
            >({{ favIds.length }})</span
          >
        </p>
      </a>
    </div>
    <!-- filters-form end  -->
    <!-- favourites-sec start  -->
    <div class="top_content">
      <div class="top_content_left">
        <p id="result">
          Showing
          <strong
            >{{ objectKeys(yachts).length }} Boats in
            {{ showresults.destination }} for the
            {{ showresults.daterange }}</strong
          >
        </p>
      </div>
      <div class="top_content_center">
        <a
          target="_blank"
          routerLink="/favourites"
          [queryParams]="{
            ids: strfavIds,
            destination: yacht.destination,
            dateValue: yacht.dateValue,
            dateEndValue: yacht.dateEndValue,
            noOfPeople: yacht.noOfPeople,
            skipper: yacht.skipper
          }"
          routerLinkActive="active"
        >
          <div class="cart">
            <i class="fas fa-heart"></i>
            <p>
              Favourites
              <span class="count" id="#edit-count-checked-checkboxes"
                >({{ favIds.length }})</span
              >
            </p>
          </div>
        </a>
      </div>
      <div class="top_content_right">
        <!-- jquery -->
        <p>Sort By</p>
        <div class="sorting-filter">
          <select
            id="img_filter"
            class="form-control pricefilter selectpicker"
            (change)="sortByPrice()"
            [(ngModel)]="sortBy"
          >
            <option value="asc">Price: Low to High</option>
            <option value="desc">Price: High to Low</option>
          </select>
        </div>
      </div>
    </div>
    <!-- favourites-sec end  -->

    <!-- Yacht-card Start  -->
    <div class="loader_container">
      <div id="load" class="center"></div>
    </div>
    <div class="yacht_container">
      <div class="row" id="yachts" *ngIf="yachts.length">
        <div
          class="col-lg-3 col-md-4 col-sm-12 visibleyatchs"
          style="display: none"
          *ngFor="let yatchItem of yachts"
        >
          <div class="img-gallery_card">
            <a href="javascript:void(0);">
              <figure>
                <a
                  target="_blank"
                  href="{{ domainName }}/detail-page?id={{
                    yatchItem['id']
                  }}&location={{ yacht.destination }}&noOfPeople={{
                    yacht.noOfPeople
                  }}&skipper={{ yacht.skipper }} &dateValue={{
                    yacht.dateValue
                  }}&dateEndValue={{ yacht.dateEndValue }}"
                >
                  <img
                    src="{{
                      yatchItem['ProfileURL']
                        ? yatchItem['ProfileURL']
                        : 'assets/images/yatch-img.png'
                    }}"
                    class="img-fluid"
                    class="yacht-card_img"
                  />
                </a>
                <div class="img-content" *ngIf="yatchItem['Annotation']">
                  <p>{{ yatchItem["Annotation"] }}</p>
                </div>
                <div class="heart_icon">
                  <div class="favourites">
                    <span
                      class="heart heart-unchecked"
                      (click)="likeUnlike($event)"
                      attr.data-id="{{ yatchItem['id'] }}"
                      ><i
                        class="fa_heart_o"
                        onclick="var state = this.className.indexOf('fa_heart_o') > -1; $(this).toggleClass('fa_heart_o', !state).toggleClass('fa_heart', state);"
                      ></i
                    ></span>
                  </div>
                </div>
              </figure>
            </a>
            <figcaption>
              <div class="figcaption_title">
                <h4>
                  <a
                    target="_blank"
                    href="{{ domainName }}/detail-page?id={{
                      yatchItem['id']
                    }}&location={{ yacht.destination }}&noOfPeople={{
                      yacht.noOfPeople
                    }}&skipper={{ yacht.skipper }} &dateValue={{
                      yacht.dateValue
                    }}&dateEndValue={{ yacht.dateEndValue }}"
                    >{{ yatchItem["Model"] }}</a
                  >
                </h4>
                <div class="flag_area">
                  <div class="ctm_flag">
                    <span
                      title="{{ yatchItem['Location_country'] }}"
                      class="flag-icon flag-icon-{{
                        getCountryFlag(yatchItem['Location_country'])
                      }}"
                    ></span>
                  </div>
                  <div class="flag_content">
                    <p>
                      {{ yatchItem["BaseName"] }},
                      {{ yatchItem["searchfieldfromwebsite"] }}
                    </p>
                    <p class="loc_region_name">
                      {{ yatchItem["Location_region_name"] }}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="figcaption_content">
                <ul>
                  <div>
                    <p>Type</p>
                    <span>{{ yatchItem["type"] }}</span>
                  </div>
                </ul>
                <ul>
                  <div>
                    <p>Year</p>
                    <span>{{ yatchItem["Boat_Age"] }}</span>
                  </div>
                </ul>
                <ul>
                  <div>
                    <p>Length</p>
                    <span>{{ yatchItem["Boat_Length"] }}m</span>
                  </div>
                </ul>
                <ul>
                  <div>
                    <p>Cabins</p>
                    <span>{{ yatchItem["Cabins"] }}</span>
                  </div>
                </ul>
                <ul>
                  <div>
                    <p>Toilets</p>
                    <span>{{ yatchItem["Toilet"] }} </span>
                  </div>
                </ul>
              </div>
              <hr />
              <div class="figcaption_features">
                <h4>Key Features</h4>
                <div class="figcaption_features__img">
                  <img
                    src="assets/images/facilities/{{ facility['name'] }}.png"
                    class="img-fluid"
                    alt="{{ facility['name'] }}"
                    title="{{ facility['name'] }}"
                    *ngFor="let facility of yatchItem['Facilities']"
                  />
                  <!-- <img src="assets/images/wind.png" class="img-fluid" alt="wind" title="{{facility.name}}"> -->
                </div>
              </div>
              <hr />
              <div class="figcaption_bottom">
                <div class="figcaption_bottom_content">
                  <!-- <ul>
                          <li><div>You are saving<span><del>£{{numberWithCommas(yatchItem['Price'])}}</del> </span> </div></li>
                          <li><div>£{{numberWithCommas(yatchItem['Savings'])}}<span>  £{{numberWithCommas(yatchItem['DiscountedPrice'])}} </span> </div></li>
                          <li><div>{{yatchItem['DiscountPercent']}} % Off<span>Yacht  only price</span> </div></li>
                        </ul> -->
                  <ul>
                    <li>
                      <div>
                        <p>{{ getSkipperText(yacht.skipper) }}</p>
                        <span>
                          £{{ numberWithCommas(yatchItem["DiscountedPrice"]) }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div>
                        {{ yatchItem["DiscountPercent"] }} % Off
                        <del>£{{ numberWithCommas(yatchItem["Price"]) }}</del>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- <div class="figcaption_bottom_content">
                          <ul>
                            <li>
                              <div>
                                <span>Price and discount are empty</span>
                              </div>
                            </li>
                          </ul>
                        </div> -->
              </div>
            </figcaption>
          </div>
        </div>
      </div>
      <div class="load_more">
        <a href="javascript:void(0);" id="loadMore">Load More</a>
      </div>
      <div class="row" class="no_data_found" *ngIf="!yachts.length">
        No data Found!
      </div>
    </div>

    <!-- Yacht-card Start  -->
  </div>
</section>
